var carousel = require("./carousel");
var zoom = require("./zoom");

module.exports = {
    initImages: function () {
        $("body").on("quickview:ready", function () {
            carousel.initMainCarousel();
            carousel.initNavCarousel();
            carousel.initMainCarousel("-zoom");
            carousel.initNavCarousel(false, "-zoom");
            zoom.initZoom();
        });
    }
};
