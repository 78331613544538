/* eslint-disable no-unused-vars */

var PubNub = require("pubnub");
var dyHelper = require("../dynamicyield/dy");
var liveTvPreferences = require("./liveTvPreferences");
var gtmHelper = require("../gtm/gtm");
var gaHelper = require("../tracking/googleanalytics");
var modalResponseHelper = require("../product/modalResponseHelper");
var imageSwap = require("../product/imageSwap");
var peTracking = require("../tracking/pushEngageTrackingEvent");
var last25Items = [];
var last24HItems = [];
var last20Items = [];
var offset = -25;
var limit = 25;
var itemsLimit = 20;
var isCRO = false;
var isExecuted = false;



/**
 * @description Returns element with id liveTvPDPSection
 * @returns {JQuery<HTMLElement>}
 */
function getLiveTvPDPSectionDiv() {
    return $("#liveTvPDPSection");
}

/**
 * @description Returns element with id liveTvAuctionLoadingSection
 * @returns {JQuery<HTMLElement>}
 */
function getLiveTvAuctionLoadingSectionDiv() {
    return $("#liveTvAuctionLoadingSection");
}

/**
 * @description Returns element with id liveTvAuctionLoadingSectionMessage
 * @returns {JQuery<HTMLElement>}
 */
function getLiveTvAuctionLoadingSectionMessageElement() {
    return $("#liveTvAuctionLoadingSectionMessage");
}

/**
 * @description Returns the value of element which id is defaultVariationValueOption
 * @returns {string}
 */
function getDefaultAttributeOptionValue() {
    return $("#defaultVariationValueOption").val();
}

/**
 * @description Returns the value of element which id is secondVariationValueOption
 * @returns {string}
 */
function getSecondAttributeOptionValue() {
    return $("#secondVariationValueOption").val();
}

/**
 * @description Returns select elements with name attributeGroupSelector
 * @returns {JQuery<HTMLElement>}
 */
function getVariationAttributeSelectors() {
    return $("select[name='attributeGroupSelector']");
}

/**
 * @description Returns input elements with name liveTvProduct
 * @returns {JQuery<HTMLElement[]>}
 */
function getLiveTvProducts() {
    return $("input[name='liveTvProduct']");
}

/**
 * @description Returns element with id selectedProductStock
 * @returns {JQuery<HTMLElement>}
 *//*
function getSelectedProductStockElement() {
   return $("#selectedProductStock");
}*/

/**
 * @description Returns element with id selectedProductID
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductIdElement() {
    return $("#selectedProductID");
}

/**
 * @description Returns element with id selectedProductName
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductNameElement() {
    return $("#selectedProductName");
}

/**
 * @description Returns element with id productSpecificationDiv
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductSpecificationElement() {
    return $("#productSpecificationDiv");
}

/**
 * @description Returns element with id productSpecificationDiv
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductDescriptionElement() {
    return $("#productDescriptionDiv");
}

/**
 * @description Returns element with id selectedProductImage
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductImageElement() {
    return $("#selectedProductImage");
}

/**
 * @description Returns element with id selectedProductViewItem
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductViewItem() {
    return $("#selectedProductViewItem");
}

/**
 * @description Returns element with id selectedProductViewItemM
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedProductViewItemM() {
    return $("#selectedProductViewItemM");
}

/**
 * @description Returns element with id liveTvProductCurrentPrice
 * @returns {JQuery<HTMLElement>}
 */
function getCurrentPriceElement() {
    return $("#liveTvProductCurrentPrice");
}

/**
 * @description Returns element with id liveTvBudgetPayInstallmentsNumber
 * @returns {JQuery<HTMLElement>}
 */
function getBudgetPayInstallmentsNumberElement() {
    return $("#liveTvBudgetPayInstallmentsNumber");
}

/**
 * @description Returns element with id liveTvBudgetPayInstallmentsAmount
 * @returns {JQuery<HTMLElement>}
 */
function getBudgetPayInstallmentsAmountElement() {
    return $("#liveTvBudgetPayInstallmentsAmount");
}

/**
 * @description Returns element with id liveTvProductStartPrice
 * @returns {JQuery<HTMLElement>}
 */
function getAuctionStartPriceElement() {
    return $("#liveTvProductStartPrice");
}

/**
 * @description Returns element with id auctionProductID
 * @returns {JQuery<HTMLElement>}
 */
function getAuctionProductIdElement() {
    return $("#auctionProductID");
}

/**
 * @description Returns element with id selectedWishlistItem
 * @returns {JQuery<HTMLElement>}
 */
function getSelectedWishlistElement() {
    return $("#liveTvWishlistBtn");
}

/**
 * @description Returns input elements with name isBuyAvailable
 * @returns {JQuery<HTMLElement[]>}
 */
function getBuyallAvailable() {
    return $("input[name='isBuyAvailable']");
}

/**
 * @description Returns checked property of element with id budgetPayCheckbox
 * @returns {Boolean}
 */
function isBudgetPaySelected() {
    try {
        var $bpCheckboxes = $("body #budgetPayCheckbox");
        if ($bpCheckboxes && $bpCheckboxes.length > 1) {
            for (var i = 0; i < $bpCheckboxes.length; i++) {
                if ($bpCheckboxes[i].checked) {
                    return $bpCheckboxes[i].checked;
                }
            }
        }

        return $bpCheckboxes ? $bpCheckboxes[0].checked : false;
    } catch (e) {
        return false;
    }
}

/**
 * @description Creates html template in liveTvPDPSection div
 * @param {string} productDetailsHtmlString
 * @returns {void}
 */
function createHtmlElementInPDPSection(productDetailsHtmlString) {
    var $liveTvPDPSectionDiv = getLiveTvPDPSectionDiv();
    var productDetailsHtml = $.parseHTML(productDetailsHtmlString);

    var parser = new DOMParser();
    var parsedHTML = parser.parseFromString(productDetailsHtmlString, "text/html");
    const prodData = JSON.parse(parsedHTML.querySelector(".live-tv-pdp-top").dataset.gtm);
    if (prodData.longDescription == "" && prodData.specification == "") {
        $liveTvPDPSectionDiv.removeClass("liveTvPDPSectionHeight");
    }
    const startingPrice = parsedHTML.querySelector("#liveTvProductStartPrice").textContent;

    const gtmProdData = {
        ecommerce:{
            value: startingPrice,
            items: [
                {
                    item_id: prodData.ID,
                    item_name: prodData.name,
                    brand: prodData.brand,
                    productType: prodData.productType,
                    primaryCategory: prodData.primaryCategory,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "",
                }
            ]
        }
    };

    gtmHelper.fireGtmEvent("LIVETVPDP", gtmProdData);
    $liveTvPDPSectionDiv.empty().append(productDetailsHtml);
}

/**
 * @description Creates html option element
 * @param {string} value Option value
 * @param {string} text Option text
 * @param {Boolean} isSelected Option selected attribute value
 * @param {Boolean} isHidden Option hidden attribute value
 * @returns {JQuery<HTMLElement>} $option
 */
function createOptionHtmlElement(value, text, isSelected, isHidden, price) {
    var optionHtmlString = "<option></option>";
    var optionHtmlElement = $.parseHTML(optionHtmlString);
    var $option = $(optionHtmlElement);
    $option.val(value);
    if (price) {
        text = text+" ( "+"$"+price+" )";
    }
    $option.text(text);
    $option.prop("selected", isSelected);
    $option.attr("hidden", isHidden);

    return $option;
}

/**
 * @description Creates default option for every atrribute selectors
 * @returns {void}
 */
function createDefaultOptionsForAllSelectors() {
    var allSelectors = getVariationAttributeSelectors();
    var selectorCount = allSelectors.length;

    if (!selectorCount) {
        return;
    }

    var defaultOptionValue = getDefaultAttributeOptionValue();
    var $defaultOption = createOptionHtmlElement(defaultOptionValue, defaultOptionValue, true);

    for (var i = 0; i < selectorCount; i++) {
        var selector = $("#attributeGroup-" + i);
        selector.empty().append($defaultOption);
    }
}

/**
 * @description Creates option for first attribute selector
 * @param {string} selectedValue Selected option value
 * @returns {void}
 */
function buildFirstVariationSelectorOptions(selectedValue) {
    var liveTvProducts = getLiveTvProducts();
    var defaultOptionValue = getDefaultAttributeOptionValue();
    var isGroupAuction = true;
    if (getVariationAttributeSelectors().length > 1) {
        isGroupAuction = false;
        defaultOptionValue = getSecondAttributeOptionValue();
    }
    var variationAttributeSelectors = getVariationAttributeSelectors();

    if (!variationAttributeSelectors.length || !liveTvProducts.length) {
        return;
    }
    var buyallAvailable = getBuyallAvailable();
    var isBuyAllApplicable = buyallAvailable[0].value.toString();
    var $firstAttributeSelector = $(variationAttributeSelectors[0]);
    var $defaultOptionHtmlElement = createOptionHtmlElement(defaultOptionValue, defaultOptionValue);
    var firstAttributeSelectorOptionArray = [];
    var noOfItemsAdded = 0;

    $firstAttributeSelector.empty().append($defaultOptionHtmlElement);

    for (var i = 0; i < liveTvProducts.length; i++) {
        var liveTvProduct = $(liveTvProducts[i]);
        var liveTvProductData = liveTvProduct.data();
        var liveTvProductStock = parseInt(liveTvProductData.productStock, 10);
        var price = isGroupAuction ? liveTvProductData.productPrice : null;
        var isArrayObj = Array.isArray(liveTvProductData.productAttributes);
        var liveTvProductAttributes = "";
        if (isArrayObj) {
            liveTvProductAttributes = liveTvProductData.productAttributes;
        } else {
            liveTvProductAttributes = JSON.parse(liveTvProductData.productAttributes);
        }
        var isBuyAllGroup = liveTvProductData.isBuyAllGroup;
        var firstAttributeValue = liveTvProductAttributes[0]; // need add some fix for pubnub drpdwn
        if (firstAttributeValue == "Buy All" && isBuyAllApplicable == "false") {
            continue;
        }
        var isHidden = !(isBuyAllGroup || liveTvProductStock);

        if (!isHidden && !firstAttributeSelectorOptionArray.includes(firstAttributeValue)) {
            if (firstAttributeValue != "Buy All" || (firstAttributeValue == "Buy All" && noOfItemsAdded > 1)) {
                var isSelected = selectedValue && selectedValue === firstAttributeValue;
                var $optionElement = createOptionHtmlElement(firstAttributeValue, firstAttributeValue, isSelected, isHidden, price);
                $firstAttributeSelector.append($optionElement);
                firstAttributeSelectorOptionArray.push(firstAttributeValue);
            }

            noOfItemsAdded++;
        }
    }
}

/**
 * @description Creates html option elements for selectors which is not first
 * @param {JQuery<HTMLElement>} currentSelector Current selector
 * @param {string} toSelectedValue Selected option value
 * @returns {void}
 */
function createNextVariationAttributesOptions(currentSelector, toSelectedValue) {
    var $currentSelector = currentSelector;
    var currentSelectorSelectedValue = $currentSelector.val();
    var currentSelectorData = $currentSelector.data();
    var currentSelectorGroupNo = currentSelectorData.attributeGroupOrder;
    var nextSelectorGroupNo = currentSelectorGroupNo + 1;
    var $nextSelector = $("#attributeGroup-" + nextSelectorGroupNo);

    if (!currentSelectorSelectedValue) {
        return;
    }

    var liveTvProducts = getLiveTvProducts();
    var defaultOptionValue = getDefaultAttributeOptionValue();

    if (!liveTvProducts || !liveTvProducts.length) {
        return;
    }

    var $defaultOptionHtmlElement = createOptionHtmlElement(defaultOptionValue, defaultOptionValue);
    var nextAttributeSelectorOptionArray = [];

    $nextSelector.empty().append($defaultOptionHtmlElement);

    for (var i = 0; i < liveTvProducts.length; i++) {
        var $liveTvProduct = $(liveTvProducts[i]);
        var liveTvProductData = $liveTvProduct.data();
        var liveTvProductAttributes = liveTvProductData.productAttributes;
        var buyAllStock = liveTvProductData.productStockbuyall;
        if (buyAllStock == 0 && liveTvProductData.isBuyAllGroup) {
            continue;
        }
        var isArrayObj = Array.isArray(liveTvProductAttributes);
        if (!isArrayObj && liveTvProductAttributes) {
            liveTvProductAttributes = JSON.parse(liveTvProductAttributes);
        }
        var liveTvProductStock = parseInt(liveTvProductData.productStock, 10);
        var isBuyAllGroup = liveTvProductData.isBuyAllGroup;
        var selectableOptionValuesForCurrentSelector = liveTvProductAttributes[currentSelectorGroupNo];
        var selectableOptionValuesForNextSelector = liveTvProductAttributes[nextSelectorGroupNo];
        var isHidden = !(isBuyAllGroup || liveTvProductStock);

        if (!isHidden && !nextAttributeSelectorOptionArray.includes(selectableOptionValuesForNextSelector) && selectableOptionValuesForCurrentSelector === currentSelectorSelectedValue) {
            var isSelected = toSelectedValue && toSelectedValue === selectableOptionValuesForNextSelector;
            var $optionElement = createOptionHtmlElement(selectableOptionValuesForNextSelector, selectableOptionValuesForNextSelector, isSelected, isHidden);
            $nextSelector.append($optionElement);
            nextAttributeSelectorOptionArray.push(selectableOptionValuesForNextSelector);
        }
    }
}

/**
 * @description Returns selected product input element
 * @returns {JQuery<HTMLElement>} Selected input product element
 */
function getSelectedProduct() {
    var liveTvProducts = getLiveTvProducts();

    if (liveTvProducts.length === 1) {
        return $(liveTvProducts[0]);
    }

    var $variationAttributeSelectors = getVariationAttributeSelectors();
    var variationAttributeSelectorsCount = $variationAttributeSelectors.length;
    var selectedVariationAttributeValuesArray = [];

    for (let i = 0; i < variationAttributeSelectorsCount; i++) {
        var $variationAttributeSelector = $("#attributeGroup-" + i);
        var selectedValue = $variationAttributeSelector.val();
        selectedVariationAttributeValuesArray.push(selectedValue);
    }

    var $selectedProduct = $("input[data-product-attributes='" + JSON.stringify(selectedVariationAttributeValuesArray) + "']");
    return $selectedProduct;
}

/**
 * @description Returns default product input element
 * @returns {JQuery<HTMLElement>} default product element
 */
function getDefaultProduct() {
    var liveTvProducts = getLiveTvProducts();
    return $(liveTvProducts[0]);
}
/**
 * @description Controls all variations are selected properly or not
 * @returns {Boolean}
 */
function isVariationSelectionOk() {
    var $variationAttributeSelectors = getVariationAttributeSelectors();

    if (!$variationAttributeSelectors.length) {
        return true;
    }

    var defaultOptionValue = getDefaultAttributeOptionValue();

    for (var i = 0; i < $variationAttributeSelectors.length; i++) {
        var $selector = $($variationAttributeSelectors[i]);
        var selectorValue = $selector.val();

        if (!selectorValue || selectorValue === defaultOptionValue) {
            return false;
        }
    }

    return true;
}

/**
 * @description Updates selected product html view
 * @returns {void}
 */
function updateSelectedProductView() {
    var isVariationSelected = isVariationSelectionOk();

    if (!isVariationSelected) {
        return;
    }

    var $selectedProduct = getSelectedProduct();

    if (!$selectedProduct || !$selectedProduct.length) {
        return;
    }
    var selectedProductData = $selectedProduct.data();
    var selectedProductName = selectedProductData.productName;
    var selectedProductPrice = selectedProductData.productPrice;
    var selectedProductID = selectedProductData.productId;
    var selectedProductStock = selectedProductData.productStock;
    var selectedProductImageUri = selectedProductData.productImage || $("#staticImgRender").val();
    var selectedProductDescription = $.parseHTML(selectedProductData.productShortDescription);
    var selectedProductSpecification = $.parseHTML(selectedProductData.productSpecification);
    var isBuyAllGroup = selectedProductData.isBuyAllGroup;
    var selectedProductQuickViewLink = selectedProductData.productQuickViewLink;
    var productStock = parseInt(selectedProductData.productStock, 10);
    var selectedQuatity = $("#productQuantityInput").val();
    var maxOrderQty = $("#maxOrderQuantity").val();
    var liveTvProduct = $(".js-live-tv-product");
    var liveTvProductAddToCardProduct = $(".livetv-add-to-cart");
    updateCurrentPrice(selectedProductPrice);
    var $selectedStartPriceElement = getAuctionStartPriceElement();
    var startPriceSel = selectedProductData.productStartprice;
    var yotpoPid = selectedProductData.yotpoProductId;
    $(".yotpo-widget-instance").attr("data-yotpo-product-id", yotpoPid);
    if (selectedProductData.yotpoTotalreviews > 0) {
        window.yotpoWidgetsContainer.initWidgets();
        $(".livetv-ratings").removeClass("d-none");
    } else {
        $(".livetv-ratings").addClass("d-none");
    }
    if (startPriceSel > 0) {
        $selectedStartPriceElement.text(startPriceSel);
    }
    if (selectedProductData.productIsbudgetpay) {
        var $budgetPayInstallmentsNumber = getBudgetPayInstallmentsNumberElement();
        var $budgetPayInstallmentsAmount = getBudgetPayInstallmentsAmountElement();
        var bpCount = Math.floor(selectedProductData.productBudgetpaycount);
        $budgetPayInstallmentsNumber.text(bpCount);
        $budgetPayInstallmentsAmount.text((parseFloat(selectedProductPrice) / bpCount).toFixed(2));
    }
    //var $selectedProductStock = getSelectedProductStockElement();
    if (!isBuyAllGroup) {
        var $selectedProductName = getSelectedProductNameElement();
        var $selectedProductID = getSelectedProductIdElement();
        var $selectedProductImage = getSelectedProductImageElement();
        var $viewItem = getSelectedProductViewItem();
        var $selectedProductSpecification = getSelectedProductSpecificationElement();
        var $selectedProductDescription = getSelectedProductDescriptionElement();
        var $selectedWishlistElement = getSelectedWishlistElement();
        var $viewItemM = getSelectedProductViewItemM();
        var productName = $selectedProductName.text(selectedProductName).closest("a.quickview");
        var productImage = $selectedProductImage.attr("src", selectedProductImageUri).closest("a.quickview");
        var productViewItem = $viewItem.attr("src", selectedProductImageUri).closest("a.quickview");
        var productViewItemM = $viewItemM.attr("src", selectedProductImageUri).closest("a.quickview");
        var selectedProductSecondImageUrl = selectedProductData.productSecondImage || selectedProductImageUri;

        productName.data("url", selectedProductQuickViewLink);
        productName.attr("data-url", selectedProductQuickViewLink);
        productName.attr("title", "Quick View for " + selectedProductName);
        productName.attr("aria-label", "Quick View for " + selectedProductName);
        $selectedProductID.text(selectedProductID).closest("a.quickview").data("url", selectedProductQuickViewLink);
        //$selectedProductStock.text(selectedProductStock);
        productImage.attr("data-url", selectedProductQuickViewLink);
        $("#selectedProductImage").attr("data-secondary-image-src", selectedProductSecondImageUrl);
        $("#selectedProductImage").attr("data-image-src", selectedProductImageUri);
        productImage.attr("title", "Quick View for " + selectedProductName);
        productImage.attr("aria-label", "Quick View for " + selectedProductName);
        productViewItem.data("url", selectedProductQuickViewLink);
        productViewItem.attr("data-url", selectedProductQuickViewLink);
        productViewItem.attr("title", "Quick View for " + selectedProductName);
        productViewItem.attr("aria-label", "Quick View for " + selectedProductName);
        productViewItemM.data("url", selectedProductQuickViewLink);
        productViewItemM.attr("data-url", selectedProductQuickViewLink);
        $selectedProductSpecification.empty().append(selectedProductSpecification);
        $selectedProductDescription.empty().append(selectedProductDescription);
        var actionUrl = $selectedWishlistElement.data("action");
        var oldPrdctID = actionUrl.substring(actionUrl.indexOf("pid=") + 4);
        actionUrl = actionUrl.replace("pid=" + oldPrdctID, "pid=" + selectedProductID);
        $selectedWishlistElement.data("action", actionUrl);
        $selectedWishlistElement.attr("data-action", actionUrl);
        if (selectedProductStock === 0) {
            liveTvProductAddToCardProduct.attr("disabled", "disabled");
            liveTvProductAddToCardProduct.addClass("disabled");
        } else {
            liveTvProductAddToCardProduct.removeAttr("disabled");
            liveTvProductAddToCardProduct.removeClass("disabled");
        }
    } else {
        var $defaultProduct = getDefaultProduct();
        var defaultProductData = $defaultProduct.data();
        selectedProductName = defaultProductData.productName;
        selectedProductID = defaultProductData.productId;
        selectedProductImageUri = defaultProductData.productImage || $("#staticImgRender").val();
        selectedProductSpecification = $.parseHTML(defaultProductData.productSpecification);
        selectedProductDescription = $.parseHTML(defaultProductData.productShortDescription);
        selectedProductQuickViewLink= defaultProductData.productQuickViewLink;
        //$selectedProductStock.text(selectedProductStock);
        // selectedProductName = $("#defaultProductName").val();
        // selectedProductID = $("#defaultProductID").val();
        var $selectedDefaultProductName = getSelectedProductNameElement();
        var $selectedDefaultProductID = getSelectedProductIdElement();
        var $selectedDefaultProductImage = getSelectedProductImageElement();
        var $selectedDefaultProductSpecification = getSelectedProductSpecificationElement();
        var $selectedProductDefaultDescription = getSelectedProductDescriptionElement();
        var $viewDefaultProductItem = getSelectedProductViewItem();
        var $viewDefaultProductItemM = getSelectedProductViewItemM();
        let $selectedWishlistElement = getSelectedWishlistElement();
        var defaultProductName = $selectedDefaultProductName.text(selectedProductName).closest("a.quickview");
        var defaultProductViewItem = $viewDefaultProductItem.attr("src", selectedProductImageUri).closest("a.quickview");
        var defaultProductViewItemM = $viewDefaultProductItemM.attr("src", selectedProductImageUri).closest("a.quickview");
        var defaultProductSecondImageUrl = defaultProductData.productSecondImage || selectedProductImageUri;

        defaultProductName.data("url", selectedProductQuickViewLink);
        defaultProductName.attr("data-url", selectedProductQuickViewLink);
        defaultProductName.attr("title", "Quick View for " + selectedProductName);
        defaultProductName.attr("aria-label", "Quick View for " + selectedProductName);
        $selectedDefaultProductID.text(selectedProductID).closest("a.quickview").data("url", selectedProductQuickViewLink);
        $selectedDefaultProductImage.attr("src", selectedProductImageUri).closest("a.quickview").data("url", selectedProductQuickViewLink);
        $("#selectedProductImage").attr("data-secondary-image-src", defaultProductSecondImageUrl);
        $("#selectedProductImage").attr("data-image-src", selectedProductImageUri);
        defaultProductViewItem.data("url", selectedProductQuickViewLink);
        defaultProductViewItem.attr("data-url", selectedProductQuickViewLink);
        defaultProductViewItem.attr("title", "Quick View for " + selectedProductName);
        defaultProductViewItem.attr("aria-label", "Quick View for " + selectedProductName);
        defaultProductViewItemM.data("url", selectedProductQuickViewLink);
        defaultProductViewItemM.attr("data-url", selectedProductQuickViewLink + "&isBudgetPay=false");
        $selectedDefaultProductSpecification.empty().append(selectedProductSpecification);
        $selectedProductDefaultDescription.empty().append(selectedProductDescription);
        let actionUrl = $selectedWishlistElement.data("action");
        let oldPrdctID = actionUrl.substring(actionUrl.indexOf("pid=") + 4);
        actionUrl = actionUrl.replace("pid=" + oldPrdctID, "pid=" + selectedProductID);
        $selectedWishlistElement.data("action", actionUrl);
        $selectedWishlistElement.attr("data-action", actionUrl);
        if (selectedProductStock === 0) {
            liveTvProductAddToCardProduct.attr("disabled", "disabled");
            liveTvProductAddToCardProduct.addClass("disabled");
        } else {
            liveTvProductAddToCardProduct.removeAttr("disabled");
            liveTvProductAddToCardProduct.removeClass("disabled");
        }
    }

    if (productStock < maxOrderQty) {
        $("#productQuantityInput").prop("max", productStock);
    } else {
        $("#productQuantityInput").prop("max", maxOrderQty);
    }

    if (selectedQuatity > productStock) {
        $("#productQuantityInput").val(productStock);
    }


    selectedProductID = getSelectedProduct().data().productId;
    liveTvProduct.attr("data-product-id", selectedProductID);
    liveTvProduct.attr("data-isBuyAll", isBuyAllGroup);
}

/**
 * Handle product variation value selection event
 */
function handleProductVariationValueSelections() {
    $(document).on("change", "select[name='attributeGroupSelector']", function () {
        var $currentSelector = $(this);
        createNextVariationAttributesOptions($currentSelector);
        updateSelectedProductView();
        $("body").trigger("liveTV:auctionRendered");

        if ($("select[name='attributeGroupSelector']").val() === "Buy All") {
            $(".live-tv-pdp-top .remove-wishlist-btn").addClass("d-none");
        }
    });
}

/**
 * Handle product quantity change event
 */
function handleQuantityChange() {
    $(document).on("click", ".live-tv-pdp-section .live-tv-decrease-quantity-value", function () {
        var $item = $(this).closest(".live-tv-pdp-section").find("#productQuantityInput");
        var quantityValue = Number($item.val());
        if (quantityValue > $item.attr("min")) {
            quantityValue--;
        }
        $item.val(quantityValue.toString());
    });

    $(document).on("click", ".live-tv-pdp-section .live-tv-increase-quantity-value", function () {
        var $item = $(this).closest(".live-tv-pdp-section").find("#productQuantityInput");
        var quantityValue = Number($item.val());
        if (quantityValue < $item.attr("max")) {
            quantityValue++;
        }
        $item.val(quantityValue.toString());
    });
}

/**
 * @description It renders no product selected error modal
 * @returns {void}
 */
function renderNoProductSelectedErrorModal() {
    var $variantSelectionErrorModal = $("#variantSelectionErrorModal");
    $(".live-tv-shop-last-section").addClass("remove-wishlist-css");
    $variantSelectionErrorModal.modal("show");
}

// Commenting below code for SLCFO-739
/**
 * @description It renders add to cart confirmation modal
 * @returns {void}
 */
/*function renderAddToCartConfirmationModal() {
    var $addToCartConfirmationModal = $("#addToCartConfirmationModal");
    $addToCartConfirmationModal.modal("show");
    $addToCartConfirmationModal.find("#continueShoppingButton").removeAttr("disabled");
}*/

/**
 * Handle product add to cart event
 */
function handleAddToCart() {
    // Commenting below code for SLCFO-739
    /*$("body").on("click", "#addToCartButton", function () {
        var isVariantSelected = isVariationSelectionOk();

        if (!isVariantSelected) {
            renderNoProductSelectedErrorModal();
            return;
        } else {
            renderAddToCartConfirmationModal();
            $(".modal-backdrop").last().css("z-index", "1045");
        }
    });*/

    $("body").on("click", "#continueToCheckout", function () {
        $(".live-tv-shop-last-section").addClass("remove-wishlist-css");
        var $thisBtn = $(this);
        var isVariantSelected = isVariationSelectionOk();
        if (!isVariantSelected) {
            renderNoProductSelectedErrorModal();
            return;
        } else {
            var addToCartUrl = window.auctions.liveTvAuction.addToCartUrl;
            $thisBtn.prop("disabled", true);

            var $product = getSelectedProduct();

            if (!$product.length) {
                return;
            }

            var productStock = $product.data("product-stock");
            var productID = $product.data("productId");

            var isBuyAllGroup = $product.data("isBuyAllGroup");
            var quantity = $thisBtn.closest(".live-tv-pdp-section").find("#productQuantityInput").val();

            var pidsObj;

            var form = {
                isBudgetPaySelected: isBudgetPaySelected(),
            };

            form.isBuyAllGroup = isBuyAllGroup;
            if (isBuyAllGroup) {
                pidsObj = productID.map(function (id) {
                    return {
                        pid: id,
                        qty: quantity,
                        options: "",
                    };
                });

                form.pidsObj = JSON.stringify(pidsObj);
            } else {
                form.pid = productID;
                form.quantity = quantity;
            }
            var dyForm = {
                pid: isBuyAllGroup ? productID.join(",") : productID,
                quantity: quantity,
                price: $thisBtn.parents(".shop-last-tile").find(".current-price-value .js-formatted-price").text(),
            };
            var gaForm = {
                id: isBuyAllGroup ? productID.join(",") : productID,
                qty: quantity,
                price: $thisBtn.data("price"),
                brand: $thisBtn.data("brand"),
                category: $thisBtn.data("category")
            };

            $("#addToCartButton").trigger("updateAddToCartFormData", form);
            $.spinner().start();
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        if (data.error) {
                            if (data.noStock) {
                                $("[data-max-quantity-text]").html(data.message);
                            }

                            gtmHelper.fireGtmEvent("Error", {
                                errorMessage: data.message
                            });

                            $("#maximum-quantity-modal").modal("show");
                            $.spinner().stop();
                        } else {
                            $.spinner().stop();
                            localStorage.setItem(productID, productStock);
                            gaHelper.fireGAEvent("LTATC", gaForm, "LIVE TV");
                            dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
                            peTracking.pushEngageEvent("WEBTV_ACTIVITY_EVENT");
                            $("#quickViewModal").modal("hide");
                            if ($thisBtn.attr("id") === "continueToCheckout") {
                                window.location = $thisBtn.data("action");
                            }
                        }
                    },
                    error: function (data) {
                        $.spinner().stop();

                        gtmHelper.fireGtmEvent("Error", {
                            errorMessage: data.responseJSON.errorMessage
                        });

                        var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                        if (modal) {
                            $(modal.modalId).modal("show");
                        }
                        $thisBtn.prop("disabled", false);
                    },
                });
            }
        }
    });

    $(document).on("click", ".shop-last-tile a.add-to-cart", function (e) {
        e.preventDefault();
        var $this = $(this),
            addToCartUrl = "/on/demandware.store/Sites-SHOP_LC-Site/default/Cart-AddProduct", //liveTvPreferences.addToCartUrl,
            productID = $this.parents(".shop-last-tile").attr("data-product-id"),
            quantity = "1";
        var shippingCharges = $this.parents(".shop-last-tile").find(".shoplast-shipping-charges").data("shippingcharges");
        var form = {
            isBudgetPaySelected: false,
            pid: productID,
            quantity: quantity,
            shippingCharges: shippingCharges >= 0 ? shippingCharges : ""
        };

        var selectedOption = $this.parents(".shop-last-tile").find("select[name='singleSelectDropdown']:visible").children("option:selected");
        if (selectedOption.length > 0 && selectedOption.val() == "Buy All") {
            var productIDs = ("" + selectedOption.data("productids")).split(",");
            var pidsObj = productIDs.map(function (id) {
                return {
                    pid: id,
                    qty: 1,
                };
            });
            form = {
                isBudgetPaySelected: false,
                pidsObj: JSON.stringify(pidsObj),
                productIDs: selectedOption.data("productids"),
                buyAllDiscount: selectedOption.data("buyalldiscount"),
                shippingCharges: shippingCharges >= 0 ? shippingCharges : ""
            };
        }
        
        var gaForm = {
            id: productID,
            qty: quantity,
            price: $this.closest(".shop-last-tile .product-current-price .js-formatted-price").text().trim().replace("$", ""),
            name: $this.closest(".shop-last-tile").attr("data-pname")
        };
        var gaFormSource = $this.closest(".shop-last-tile").attr("data-itemtype");
        $(this).trigger("updateAddToCartFormData", form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function (data) {
                    if (!data.error) {
                        $("body").trigger("product:afterAddToCart", data);
                        $("body").trigger("fpcProduct:afterAddToCart", data);

                        const $minicartQuantityElement = $(".minicart-quantity");

                        if (data.quantityTotal && $minicartQuantityElement.length > 0) {
                            $minicartQuantityElement.html(data.quantityTotal);
                        }

                        if (data.quantityTotal > 0) {
                            $minicartQuantityElement.addClass("d-block");
                        }
                        if (gaFormSource == "24") {
                            gaHelper.fireGAEvent("LTATC", gaForm, "Last " + gaFormSource + "hours items");
                        } else {
                            gaHelper.fireGAEvent("LTATC", gaForm, "Last " + gaFormSource + " items");
                        }
                        peTracking.pushEngageEvent("WEBTV_ACTIVITY_EVENT");
                    } else {
                        if (data.noStock) {
                            $("[data-max-quantity-text]").html(data.message);
                        }
                        else {
                            $("[data-max-quantity-text]").html($(".max-quantity-modal__title__text").attr("data-max-quantity-text"));
                        }
                        $("#maximum-quantity-modal").modal("show");

                    }
                    $.spinner().stop();
                },
                error: function (data) {
                    $.spinner().stop();
                    var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                    if (modal) {
                        $(modal.modalId).modal("show");
                    }
                },
            });
        }
    });
}

/**
 * @description Creates pubnub channel and subscribe it live tv auction channel
 * @returns {PubNub}
 */
function subscribePubNubChannel() {
    var publishKey = window.auctions.liveTvAuction.pubnubPublishKey;
    var subscribeKey = window.auctions.liveTvAuction.pubnubSubscribeKey;
    var subscribedChannels = window.auctions.liveTvAuction.pubnubLiveTvAuctionChannel;

    var pubNub = new PubNub({
        publishKey: publishKey,
        subscribeKey: subscribeKey,
        restore: true,
        autoNetworkDetection: true
    });

    pubNub.subscribe({
        channels: [subscribedChannels],
        withPresence: true,
    });

    return pubNub;
}

/**
 * @description Updates live tv auction current price
 * @param {string} currentPrice Current price
 * @returns {void}
 */
function updateCurrentPrice(currentPrice) {
    if (!currentPrice) {
        return;
    }

    var $liveTvProductCurrentPrice = getCurrentPriceElement();

    $liveTvProductCurrentPrice.text(currentPrice);
}

/**
 * @description Updates budget pay info
 * @param {string} currentPrice Current price
 * @param {Object} budgetPay Budget pay info object
 * @returns {void}
 */
function updateBudgetPay(currentPrice, budgetPay) {
    if (!currentPrice) {
        return;
    }

    var $budgetPayInstallmentsNumber = getBudgetPayInstallmentsNumberElement();
    var $budgetPayInstallmentsAmount = getBudgetPayInstallmentsAmountElement();

    $budgetPayInstallmentsNumber.text(budgetPay.emiCount.toFixed());
    $budgetPayInstallmentsAmount.text((parseFloat(currentPrice) / budgetPay.emiCount).toFixed(2));
}

/**
 * @description Updates product input elements with data coming from pubnub
 * @param {Array<Object>} tree Live tv product object tree
 * @param {Array<string>} attributeValueChain Live tv prodcut attribute chain array
 * @param {Boolean} isBuyAllAttribute Live tv product buy all attribute flag
 * @returns {void}
 */
function updateLiveTvProducts(tree, attributeValueChain, isBuyAllAttribute) {
    if (!tree || !Array.isArray(tree)) {
        return [];
    }

    for (var i = 0; i < tree.length; i++) {
        var node = tree[i];
        var products = node.products;
        var productAttributeValue = node.productAttributeValue;
        var productID = node.productID || JSON.stringify(node.productIDs);
        var updatedAttributeValueChain = [];
        isBuyAllAttribute = isBuyAllAttribute || node.isBuyAllAttribute;

        if (attributeValueChain && Array.isArray(attributeValueChain)) {
            updatedAttributeValueChain = attributeValueChain.slice();
        }

        if (productAttributeValue) {
            updatedAttributeValueChain.push(productAttributeValue);
        }

        if (products && Array.isArray(products)) {
            updateLiveTvProducts(products, updatedAttributeValueChain, isBuyAllAttribute);
        } else if (productID) {
            var $hiddenInput = $("input[data-product-id='" + productID + "']");
            var $buyAllgrp = $("input[data-product-buyAllGroup='" + node.productAttributeValue + "']");
            if ($hiddenInput.length) {
                var $liveTvProduct = $($hiddenInput[0]);
                $liveTvProduct.data("productStock", node.productStock);
                $liveTvProduct[0].dataset.productPrice = node.price;
                $liveTvProduct.data("product-price", node.price);
                $liveTvProduct[0].dataset.productAttributes =  JSON.stringify(updatedAttributeValueChain);
                $liveTvProduct.data("product-attributes", JSON.stringify(updatedAttributeValueChain));
            }
            if ($buyAllgrp.length && node.hasOwnProperty("productIDs")) { // eslint-disable-line no-prototype-builtins
                var $group = $($buyAllgrp[0]);
                $group[0].dataset.productPrice = node.price;
                $group.data("product-price", node.price);
                $group[0].dataset.productAttributes =  JSON.stringify(updatedAttributeValueChain);
                $group.data("product-attributes", JSON.stringify(updatedAttributeValueChain));
                if (node.hasOwnProperty("productStockBuyAll") && node.productStockBuyAll >= 0) { // eslint-disable-line no-prototype-builtins
                    $group[0].dataset.productStockbuyall = node.productStockBuyAll;
                    $group.data("product-stockbuyall", node.productStockBuyAll);
                }
                
            }
        }
    }
}

/**
 * @description Updates selected product stock info with data coming from pubnub
 * @returns {void}
 */
function updateSelectedProductStock() {
    var $selectedProduct = getSelectedProduct();
    var liveTvProducts = getLiveTvProducts();

    if (!$selectedProduct.length || !liveTvProducts.length) {
        return;
    }

    /*var selectedProductData = $selectedProduct.data();
    var selectedProductID = selectedProductData.productId;
    //var selectedProductStockElement = getSelectedProductStockElement();

    for (let i = 0; i < liveTvProducts.length; i++) {
        var liveTvProduct = $(liveTvProducts[i]);
        var liveTvProductData = liveTvProduct.data();
        var liveTvProductID = liveTvProductData.productId;

        if (liveTvProductID === selectedProductID) {
            var liveTvProductStock = liveTvProductData.productStock;
            //selectedProductStockElement.text(liveTvProductStock);
        }
    }*/
}
/**
 * set the value of buyAll availablity flag from 
 * pubnub
 * @param {*} isBuyallAvailable 
 */
function updateBuyallAvailablity(isBuyallAvailable) {
    var buyAllObj = getBuyallAvailable();
    buyAllObj.attr("value", isBuyallAvailable);
}

/**
 * @description Updates variation attribute selectors option with data coming from pubnub
 * @returns {void}
 */
function updateVariationAttributeSelectors() {
    var $variationAttributeSelectors = getVariationAttributeSelectors();
    var variationAttributeSelectorsCount = $variationAttributeSelectors.length;

    if (!variationAttributeSelectorsCount) {
        return;
    }

    var firstVariationAttributeSelectedValue = $("#attributeGroup-0").find(":selected").val();
    buildFirstVariationSelectorOptions(firstVariationAttributeSelectedValue);

    for (let i = 1; i < variationAttributeSelectorsCount; i++) {
        var variationAttributeSelector = $("#attributeGroup-" + (i - 1));
        var toSelectedValue = $("#attributeGroup-" + i)
            .find(":selected")
            .val();
        createNextVariationAttributesOptions(variationAttributeSelector, toSelectedValue);
    }
}

/**
 * @description Updates product details parent function
 * @param {Object} productDetailsObject Product details object
 * @returns {void}
 */
function updateProductsDetails(productDetailsObject) {
    if (!productDetailsObject) {
        return;
    }


    updateCurrentPrice(productDetailsObject.currentPrice);
    updateBudgetPay(productDetailsObject.currentPrice, productDetailsObject.budgetPay);
    updateLiveTvProducts(productDetailsObject.productTree);
    var allSelectors = getVariationAttributeSelectors();
    var selectorCount = allSelectors.length || 0;
    var selectedOptionValues = [];
    for (var i = 0; i < selectorCount; i++) {
        selectedOptionValues.push($(allSelectors[i]).find("option:selected").val());
    }
    for (var j = 0; j < selectorCount; j++) {
        $("#attributeGroup-"+j).val(selectedOptionValues[j]).trigger("change");
    }
    if (selectorCount > 0) {
        updateBuyallAvailablity(productDetailsObject.isBuyAllAvailable);
    }
    updateSelectedProductStock();
    updateVariationAttributeSelectors();
}

function updateQuickViewUrl(productDetailsObject) {
    if (!productDetailsObject) {
        return;
    }

    var $quickview = $(document).find("#liveTvPDPSection").find(".quickview");

    if ($quickview.length && productDetailsObject.currentPrice) {
        $.each($quickview, function (i, el) {
            var url = $(el).attr("data-url");
            var originalUrl = $(el).attr("data-original-url");

            if (!originalUrl) {
                originalUrl = url;
                $(el).attr("data-original-url", url);
            }

            $(el).attr("data-url", originalUrl + "&current=" + productDetailsObject.currentPrice);
        });
    }
}

/**
 * @description It triggers render-liveTv-pdp event when current auction main product is different from product id in message coming from pubnub
 * @param {Object} productDetailsObject Product details object
 * @returns {void}
 */
function triggerRenderLiveTvProductDetailsIfNeeded(productDetailsObject) {
    var auctionProductId = productDetailsObject.productID;
    var auctionStartPrice = productDetailsObject.startPrice;
    var currentAuctionProductId = getAuctionProductIdElement().val();
    var currentAuctionStartPrice = getAuctionStartPriceElement().val();

    if (auctionProductId !== currentAuctionProductId && auctionStartPrice !== currentAuctionStartPrice) {
        $(document).trigger("render-liveTv-pdp", [{ auctionProductID: auctionProductId }]);
    }
}

/**
 * @description PDP components before rendering operations
 * @returns {Promise}
 */
function beforeMountLiveTvProductDetails(data) {
    var $liveTvAuctionLoadingSectionDiv = getLiveTvAuctionLoadingSectionDiv();
    $liveTvAuctionLoadingSectionDiv.attr("hidden", false);
    var auctionProductID = data && data.auctionProductID ? data.auctionProductID : null;

    return $.ajax({
        method: "POST",
        url: liveTvPreferences.getLiveTvProductUrl,
        data: {
            auctionProductID: auctionProductID,
        },
    });
}

/**
 * @description PDP components rendering operations
 * @param {string} productTemplateString Product template string
 * @returns {void}
 */
function mountLiveTvProductDetails(productTemplateString) {
    if (!productTemplateString) {
        return;
    }

    var $liveTvAuctionLoadingSectionDiv = getLiveTvAuctionLoadingSectionDiv();
    $liveTvAuctionLoadingSectionDiv.attr("hidden", true);

    var $liveTvPDPSectionDiv = getLiveTvPDPSectionDiv();
    $liveTvPDPSectionDiv.attr("hidden", false);
    createHtmlElementInPDPSection(productTemplateString);
    createDefaultOptionsForAllSelectors();
    buildFirstVariationSelectorOptions();
    imageSwap.imageSwap();
}

var numberOfPendingAttempts = 0;

function logNumberOfAttemptsToGetProductData(attempts) {
    return $.ajax({
        method: "POST",
        url: liveTvPreferences.logLiveTvProductPendingUrl,
        data: {
            attempts: attempts
        },
    });
}


/**
 * @description PDP components after rendering operations
 * @returns {void}
 */
function afterMountLiveTvProductDetails() {

    var channel = subscribePubNubChannel();
    window.yotpoWidgetsContainer.initWidgets();


    channel.addListener({
        message: function (event) {
            var message = event.message;
            if (message && message.status && message.status === 11) {
                getLiveTvPDPSectionDiv().attr("hidden", true);
                getLiveTvAuctionLoadingSectionDiv().attr("hidden", false);
                getLiveTvAuctionLoadingSectionMessageElement().text(liveTvPreferences.noAuctionMessage);
                numberOfPendingAttempts += 1;
                return;
            }

            var attempts = numberOfPendingAttempts;
            numberOfPendingAttempts = 0;

            if (attempts > 0 && liveTvPreferences.logLiveTvProductPendingUrl && liveTvPreferences.logLiveTvProductPendingUrl.length > 0) {
                logNumberOfAttemptsToGetProductData(attempts).then(function () {
                    triggerRenderLiveTvProductDetailsIfNeeded(message);
                    updateProductsDetails(message);
                    updateQuickViewUrl(message);
                });
            } else {
                triggerRenderLiveTvProductDetailsIfNeeded(message);
                updateProductsDetails(message);
                updateQuickViewUrl(message);
            }
        },
    });
}

/**
 * @description Event loop of PDP component
 * @returns {void}
 */
function renderLiveTvProductDetails(data) {
    beforeMountLiveTvProductDetails(data).then(function (response) {
        var $liveTvPDPSectionDiv = getLiveTvPDPSectionDiv();
        // if the auction is in progress then display the product
        if (response && response.productTemplate) {
            mountLiveTvProductDetails(response.productTemplate);
            gaHelper.fireGAEvent("PDP", { pagetype: $(".experience-commerce_assets-liveTV #pageType").length > 0 ? "Homepage" : "PDP" });
            if (!isExecuted) {

                afterMountLiveTvProductDetails();
            }
            // if the auction ended then the template is empty but it's not an error
        } else if (response && response.error === false && !response.productTemplate) {
            // display undefinately the loading message
            $liveTvPDPSectionDiv.attr("hidden", true);
            getLiveTvAuctionLoadingSectionMessageElement().text(liveTvPreferences.noAuctionMessage);
            // else it's an error
        } else {
            var $loadingSectionMessage = getLiveTvAuctionLoadingSectionMessageElement();
            $liveTvPDPSectionDiv.attr("hidden", true);
            $loadingSectionMessage.text(liveTvPreferences.technicalError);
        }
        $("body").trigger("liveTV:auctionRendered");
    });
}

/**
 * Trigger re-run event loop of PDP component
 */
function renderLiveTvProductDetailsEvent() {
    $(document).on("render-liveTv-pdp", function (e, data) {
        renderLiveTvProductDetails(data);
        isExecuted = true;
        dyHelper.fireDYEvent("SPA_LIVE_TV");
    });
}

/**
 * Shop Last Events
 */
function shopLastEvents() {
    var $shopLastSection = $(".live-tv-shop-last"),
        $shopLastGrid = $shopLastSection.find(".shop-last-grid"),
        $gridTypeSwitch = $shopLastSection.find(".grid-type-switch .grid-type");

    $shopLastSection.attr("data-grid-type", $gridTypeSwitch.filter(".active").attr("data-grid-type"));
    $shopLastGrid.attr("data-grid-type", $gridTypeSwitch.filter(".active").attr("data-grid-type"));

    $gridTypeSwitch.off("click").on("click", function (e) {
        var $this = $(this),
            gridType = $this.attr("data-grid-type");

        e.preventDefault();

        $this.addClass("active").siblings(".grid-type").removeClass("active");
        $shopLastSection.attr("data-grid-type", $gridTypeSwitch.filter(".active").attr("data-grid-type"));
        $shopLastGrid.attr("data-grid-type", gridType);
        shopLast24HoursEvents();
        if ($("[data-grid-type='grid-type-list']").hasClass("active")) {
            $(".more-options-msg").hide();
        } else {
            $(".more-options-msg").show();
        }
    });

    $("body").on("click", ".shop-last-tile .bp-value", function () {
        $(this).closest(".bp-price").find(".alert.tooltip-popup").addClass("show").removeClass("d-none");
    });
}

/**
 * Shop Last 24 Hours Events
 */
function shopLast24HoursEvents() {
    var gridType = $(".live-tv-shop-last").attr("data-grid-type"),
        $shopLast24hCarousel = $(".shoplast24h-carousel");

    if ($shopLast24hCarousel.length > 0) {
        $shopLast24hCarousel.off("click", ".hour-item").on("click", ".hour-item", function () {
            var $this = $(this),
                shopLast24hItems = JSON.parse($this.attr("data-items"));

            $this.closest(".shoplast24h-carousel").find(".hour-item").removeClass("selected");
            $this.addClass("selected");
            renderLiveTvShopLast24HoursItems(shopLast24hItems);
        });

        if (gridType === "grid-type-table" || $(window).width() < 769) {
            $shopLast24hCarousel.on("initialized.owl.carousel", function (event) {
                var $firstItem = $(event.target).find(".owl-item").eq(event.item.index),
                    items = $firstItem.find(".hour-item").attr("data-items"),
                    shopLast24hItems = typeof items !== "undefined" ? JSON.parse(items) : [];

                $(event.target).find(".hour-item").removeClass("selected");
                $firstItem.find(".hour-item").addClass("selected");
                renderLiveTvShopLast24HoursItems(shopLast24hItems);
            });

            $shopLast24hCarousel.owlCarousel({
                loop: false,
                dots: false,
                nav: true,
                margin: 5,
                smartSpeed: 500,
                responsiveClass: true,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
                responsive: {
                    0: {
                        nav: true,
                        items: 2
                    },
                    544: {
                        nav: true,
                        items: 4
                    },
                    992: {
                        nav: true,
                        items: 6
                    }
                }
            });
        }
        // else if (gridType === "grid-type-list" && $(window).width() > 768) {
        //     $shopLast24hCarousel.owlCarousel("destroy");
        // }
    }
}

// Function to start the countdown for each product
function startCountdown() {
    $(".countdown.counting").each(function () {
        var $this = $(this);
        if ($this.data("countdown-initialized")) {
            return;
        }

        $this.data("countdown-initialized", true);
        var countdownDuration = parseInt($this.data("end-date"), 10);

        function updateTimer() {
            if (countdownDuration <= 0) {
                $this.parent(".auction-timer-section").addClass("d-none");
                $this.data("end-date", 0);
                $this.removeClass("counting");
                clearInterval(timerInterval);  // Stop the timer when it expires
                return;
            }
            // Calculate hours, minutes, and seconds
            var hours = Math.floor(countdownDuration / 3600);
            var minutes = Math.floor((countdownDuration % 3600) / 60);
            var seconds = countdownDuration % 60;

            // Ensure two digits for hours, minutes, and seconds
            hours = String(hours).padStart(2, "0");
            minutes = String(minutes).padStart(2, "0");
            seconds = String(seconds).padStart(2, "0");

            // Display the countdown in "hh:mm:ss" format
            $this.html("Price Expires in " + hours + " : " + minutes + " : " + seconds);

            // Decrease the countdown duration
            countdownDuration--;
        }

        // Update the timer every second
        var timerInterval = setInterval(updateTimer, 1000);

        // Initially update the timer right after page load
        updateTimer();
    });
}

$(window).on("resize", function () {
    shopLast24HoursEvents();
    displayRecentlyOnAirItems();
});

$(document).ready(function () {
    displayRecentlyOnAirItems();
    if ($("body").hasClass("CRO_generic_color")) {
        isCRO = true;
    } else {
        isCRO = false;
    }

    peTracking.pushEngageEvent("WEBTV_BROWSE_EVENT");
});

/**
 * @description Shop Last 24 Hours Items
 * @param {Object} shopLast24hItems Products objects array
 * @returns {void}
 */
function renderLiveTvShopLast24HoursItems(shopLast24hItems) {
    var settings = $("#shopLastSettings").data();
    if (typeof settings === "undefined") {
        return;
    }
    var $liveTvShopLast24Grid = $("#liveTvShopLast24Grid");
    var $tilesGridWrap = $("<div/>", { class: "product-grid shop-last-grid d-flex flex-wrap" });
    var position = 1;
    shopLast24hItems.forEach(function (item) {
        var productId = item.stockCode;
        var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
        path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
        var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
        var baseProdURLObj = new URL(baseProdURL);
        baseProdURLObj.searchParams.append("w", 300);
        baseProdURLObj.searchParams.append("h", 300);
        var productImageUrl = baseProdURLObj.href.toString();
        var productSecondImageUrl = productImageUrl.split(".jpg");
        if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
            productSecondImageUrl = productImageUrl;
        } else {
            productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
            productSecondImageUrl = productSecondImageUrl.join(".jpg");
        }
        // const productSecondImageUrl = settings.thumbSecondImageUrl.replace("{path}", path) .replace("{position}", 1 ).replace("{position}", 1 );

        var currentPrice = item.price;
        var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
        var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

        var itemObj = {
            productid: productId,
            productname: item.name,
            productImageUrl: productImageUrl,
            productSecondImageUrl: productSecondImageUrl,
            currentprice: currentPrice,
            estRetValueVal: estRetValueVal,
            estSavingsVal: estSavingsVal,
            emicount: item.emicount,
            itemType: "24",
            select1: item.singleVariants,
            select2: item.multiVariants,
            sizes : item.multiVariants,
            SkuProductId: item.groupNames,
            isMLA: item.isMLA,
            isGroupAuction: item.isGroupAuction,
            isBuyAll: item.isBuyAll,
            buyAllDiscount: item.buyAllDiscount,
            buyAllPrice: item.buyAllPrice,
            shippingCharges: item.shippingCharges,
            actualAuctionEndTime: item.actualAuctionEndTime,
            isAvailable: item.isAvailable
        };


        // var optionSelectObj = {};

        // itemObj.sizes.forEach(function(item){
        //     optionSelectObj[item.productId] = item.bText;
        // });

        // itemObj['optionSelectObj'] = optionSelectObj;

        // console.log(optionSelectObj+ "abc");

        // optionSelectArray = itemObj.sizes;

        // console.log(itemObj.select1 + "saf");
        $tilesGridWrap.append(renderShopLastItem(itemObj, settings));

        var last24HItem = {
            id: productId,
            name: item.name,
            price: currentPrice,
            list: "LIVE TV - Last 24 hours Items",
            position: "" + position
        };
        last24HItems.push(last24HItem);
        position = position + 1;
    });

    $liveTvShopLast24Grid.html($tilesGridWrap);
    // Call the countdown function
    startCountdown();
    shopLastEvents();
    staticImageRender();
    setTimeout(function () {
        if (last24HItems.length > 0) {
            var noOfImpressions = 0;
            last24HItems.forEach(function (LTLastItem) {
                gaHelper.fireGAEvent("AddListItem", LTLastItem);
                noOfImpressions++;
                if (noOfImpressions == 20) {
                    noOfImpressions = 0;
                    gaHelper.fireGAEvent("FireListItem");
                }
            });
            if (noOfImpressions % 20 != 0) {
                gaHelper.fireGAEvent("FireListItem");
            }
        }
    }, 5000);
}

/**
 * @description Shop Last 25 Items
 * call LiveTv-GetLast25Attributes to get liveTV products attributes
 * @param {object} settings
 * @param {object} herokuResponse
 */
function updateLastAttributesFromSFCC(settings, herokuResponse) {
    try {
        if (!herokuResponse || !herokuResponse.products) {
            return;
        }

        var getLast25Attributes = function () {
            return $.ajax({
                url: settings.getLast25AttributesUrl,
                method: "POST",
                data: { products: herokuResponse.products && Array.isArray(herokuResponse.products) ? JSON.stringify(herokuResponse.products) : {} }
            });
        };

        getLast25Attributes().then(function (response) {
            for (var i = 0; i < response.productAttributes.length; i++) {
                if (!response.productAttributes[i].isAvailable) {
                    var itemBadge = "[data-product-id=" + response.productAttributes[i].productID + "]" + " .sold-out-badge";
                    $(itemBadge).show();
                }
                if (response.productAttributes[i].hasVariations) {
                    var itemVariation = "[data-product-id=" + response.productAttributes[i].productID + "]" + " .more-options-msg";
                    $(itemVariation).css("visibility", "visible");
                }
                if (response.productAttributes[i].productPrice) {
                    var itemPrice = "[data-product-id=" + response.productAttributes[i].productID + "]" + " .shoplc-price-value";
                    $(itemPrice).text(response.productAttributes[i].productPrice);
                    if (response.productAttributes[i].hasBudgetPay) {
                        var installmentPrice = "[data-product-id=" + response.productAttributes[i].productID + "]" + " .item-installments-amount span";
                        $(installmentPrice).text(response.productAttributes[i].installmentAmount);
                        var installmentNumber = "[data-product-id=" + response.productAttributes[i].productID + "]" + " .item-installments-number";
                        $(installmentNumber).text(response.productAttributes[i].installmentsNumber);
                    } else {
                        $("[data-product-id=" + response.productAttributes[i].productID + "]").find(".product-current-price.bp-price").remove();
                    }
                }
            }
        });
    } catch (e) {
        return;
    }
}

// /**
//  * @description Shop Last Items
//  * @returns {void}
//  */
// function renderLiveTvShopLast25Items() {
//     var settings = $("#shopLastSettings").data();

//     if (typeof settings === "undefined") {
//         return;
//     }

//     var $tilesGridWrap = $("<div/>", {class: "product-grid shop-last-grid d-flex flex-wrap"});
//     var position = 1;

//     $(window).on("scroll", function () {
//         var scrollDistance = $(window).scrollTop() + $(window).height();
//         var footerDistance = $(".footer-block").offset().top;
//         var stopAPICall = $("#liveTvShopLast25").hasClass("js-hit-api");
//         if (scrollDistance >= footerDistance) {
//             var $liveTvShopLast25 = $("#liveTvShopLast25");
//             var getLast25Items = function () {
//                 if (stopAPICall) {
//                     $("#liveTvShopLast25").removeClass("js-hit-api");
//                     offset = offset + 25;
//                     return $.ajax({
//                         url: settings.shopLast25ItemsUrl+"?offset="+offset+"&limit="+limit,
//                         dataType: "json",
//                         method: "GET"
//                     });
//                 } else {
//                     return false;
//                 }
//             };
//             getLast25Items().then(function (response) {
//                 var itemsCount = response && response.products ? response.products.length : 0;
//                 if (itemsCount > 0) {
//                     for (var i = 0; i < itemsCount; i++) {
//                         var item = response.products[i];
//                         const productId = item.productid;
//                         const path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
//                         const productImageUrl = settings.thumbImageUrl.replace("{path}", path);

//                         var currentPrice = item.currentprice;
//                         var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
//                         var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

//                         var itemObj = {
//                             productid: productId,
//                             productname: item.productname,
//                             productImageUrl: productImageUrl,
//                             currentprice: currentPrice,
//                             estRetValueVal: estRetValueVal,
//                             estSavingsVal: estSavingsVal,
//                             emicount: item.emicount,
//                             itemType: "25"
//                         };

//                         $tilesGridWrap.append(renderShopLastItem(itemObj, settings));
//                         var last25Item = {
//                             id: productId,
//                             name: item.productname,
//                             price: currentPrice,
//                             list: "LIVE TV - Last 25 Items",
//                             position: "" + position
//                         };
//                         last25Items.push(last25Item);
//                         position = position + 1;
//                     }

//                     $liveTvShopLast25.append($tilesGridWrap);
//                     $("#liveTvShopLast25").addClass("js-hit-api");
//                     shopLastEvents();
//                     updateLastAttributesFromSFCC(settings, response);
//                     staticImageRender();
//                 } else {
//                     $("#liveTvShopLast25").removeClass("js-hit-api");
//                 }
//             }).then(function () {
//                 $(document).trigger("liveTV:productsRendered");
//                 setTimeout(function () {
//                     if (last25Items.length > 0) {
//                         var noOfImpressions = 0;
//                         last25Items.forEach(function (LTLastItem) {
//                             gaHelper.fireGAEvent("AddListItem", LTLastItem);
//                             noOfImpressions++;
//                             if (noOfImpressions == 20) {
//                                 noOfImpressions = 0;
//                                 gaHelper.fireGAEvent("FireListItem");
//                             }
//                         });
//                         if (noOfImpressions % 20 != 0) {
//                             gaHelper.fireGAEvent("FireListItem");
//                         }
//                         last25Items = [];
//                     }
//                 }, 5000);
//             });
//         }
//     }).scroll();
// }

/**
 * @description Shop Last Items 25 Products
 * @returns {void}
 */
function displayLiveTvShopLast25Items() {
    var settings = $("#shopLastSettings").data();

    if (typeof settings === "undefined") {
        return;
    }

    var $tilesGridWrap = $("<div/>", {
        class: "product-grid shop-last-grid d-flex flex-wrap"
    });
    var position = 1;

    $(window).on("scroll", function () {
        var scrollDistance = $(window).scrollTop() + $(window).height();
        var footerOffset = $(".footer-block").offset();
        var footerDistance = footerOffset !== undefined ? $(".footer-block").offset().top : 0;
        var stopAPICall = $("#liveTvShopLast25").hasClass("js-hit-api");
        if (scrollDistance >= footerDistance) {
            var $liveTvShopLast25 = $("#liveTvShopLast25");
            var getLast25Items = function () {
                if (stopAPICall) {
                    $("#liveTvShopLast25").removeClass("js-hit-api");
                    offset = offset + 25;
                    return Promise.resolve($.ajax({
                        url: settings.shopLast25ItemsUrl + "?offset=" + offset + "&limit=" + limit,
                        dataType: "json",
                        method: "GET"
                    }));
                } else {
                    return Promise.resolve(false);
                }
            };
            getLast25Items().then(function (response) {
                var itemsCount = response && response.products ? response.products.length : 0;
                if (itemsCount > 0) {
                    for (var i = 0; i < itemsCount; i++) {
                        var item = response.products[i];
                        var productId = item.productid;
                        var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
                        path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
                        var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
                        var baseProdURLObj = new URL(baseProdURL);
                        baseProdURLObj.searchParams.append("w", 300);
                        baseProdURLObj.searchParams.append("h", 300);
                        var productImageUrl = baseProdURLObj.href.toString();
                        var productSecondImageUrl = productImageUrl.split(".jpg");
                        if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
                            productSecondImageUrl = productImageUrl;
                        } else {
                            productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
                            productSecondImageUrl = productSecondImageUrl.join(".jpg");
                        }

                        // const productSecondImageUrl = settings.thumbSecondImageUrl.replace("{path}", path) .replace("{position}", 1 ).replace("{position}", 1 );

                        var currentPrice = item.currentprice;
                        var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
                        var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

                        var itemObj = {
                            productid: productId,
                            productname: item.productname,
                            productImageUrl: productImageUrl,
                            productSecondImageUrl: productSecondImageUrl,
                            currentprice: currentPrice,
                            estRetValueVal: estRetValueVal,
                            estSavingsVal: estSavingsVal,
                            emicount: item.emicount,
                            itemType: "25"
                        };

                        $tilesGridWrap.append(renderShopLastItem(itemObj, settings));
                        var last25Item = {
                            id: productId,
                            name: item.productname,
                            price: currentPrice,
                            list: "LIVE TV - Last 25 Items",
                            position: "" + position
                        };
                        last25Items.push(last25Item);
                        position = position + 1;
                    }

                    $liveTvShopLast25.append($tilesGridWrap);
                    $("#liveTvShopLast25").addClass("js-hit-api");
                    shopLastEvents();
                    updateLastAttributesFromSFCC(settings, response);
                    staticImageRender();
                } else {
                    $("#liveTvShopLast25").removeClass("js-hit-api");
                }
            }).then(function () {
                $(document).trigger("liveTV:productsRendered");
                setTimeout(function () {
                    if (last25Items.length > 0) {
                        var noOfImpressions = 0;
                        last25Items.forEach(function (LTLastItem) {
                            gaHelper.fireGAEvent("AddListItem", LTLastItem);
                            noOfImpressions++;
                            if (noOfImpressions == 20) {
                                noOfImpressions = 0;
                                gaHelper.fireGAEvent("FireListItem");
                            }
                        });
                        if (noOfImpressions % 20 != 0) {
                            gaHelper.fireGAEvent("FireListItem");
                        }
                        last25Items = [];
                    }
                }, 5000);
            });
        }

    });

}
/**
 * @description Shop Last Items 20 Products
 * @returns {void}
 */
function displayLiveTvShopLast20Items() {
    var settings = $("#shopLastSettings").data();

    if (typeof settings === "undefined") {
        return;
    }

    var $tilesGridWrap = $("<div/>", { class: "product-grid shop-last-grid d-flex flex-wrap" });
    var position = 1;

    var scrollDistance = $(window).scrollTop() + $(window).height();
    var footerOffset = $(".live-tv-shop-recent-section").offset();
    var footerDistance = footerOffset !== undefined ? $(".live-tv-shop-recent-section").offset().top : 0;
    var stopAPICall = $("#liveTvShopRecent25").hasClass("js-hit-api");
    if (scrollDistance >= footerDistance) {
        var $liveTvShopLast25 = $("#liveTvShopRecent25");
        var getLast20Items = function () {
            if (stopAPICall) {
                $("#liveTvShopRecent25").removeClass("js-hit-api");
                offset = offset + 25;
                return Promise.resolve($.ajax({
                    url: settings.shopLast25ItemsUrl + "?offset=" + offset + "&limit=" + itemsLimit,
                    dataType: "json",
                    method: "GET"
                }));
            } else {
                return Promise.resolve(false);
            }
        };
        getLast20Items().then(function (response) {
            var itemsCount = response && response.products ? response.products.length : 0;
            if (itemsCount > 0) {
                for (var i = 0; i < itemsCount; i++) {
                    var item = response.products[i];
                    var productId = item.productid;
                    var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
                    path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
                    var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
                    var baseProdURLObj = new URL(baseProdURL);
                    baseProdURLObj.searchParams.append("w", 300);
                    baseProdURLObj.searchParams.append("h", 300);
                    var productImageUrl = baseProdURLObj.href.toString();
                    var productSecondImageUrl = productImageUrl.split(".jpg");
                    if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
                        productSecondImageUrl = productImageUrl;
                    } else {
                        productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
                        productSecondImageUrl = productSecondImageUrl.join(".jpg");
                    }

                    // const productSecondImageUrl = settings.thumbSecondImageUrl.replace("{path}", path) .replace("{position}", 1 ).replace("{position}", 1 );

                    var currentPrice = item.currentprice;
                    var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
                    var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

                    var itemObj = {
                        productid: productId,
                        productname: item.productname,
                        productImageUrl: productImageUrl,
                        productSecondImageUrl: productSecondImageUrl,
                        currentprice: currentPrice,
                        estRetValueVal: estRetValueVal,
                        estSavingsVal: estSavingsVal,
                        emicount: item.emicount,
                        itemType: "20"
                    };

                    $tilesGridWrap.append(renderRecentAiredItem(itemObj, settings));
                    var last25Item = {
                        id: productId,
                        name: item.productname,
                        price: currentPrice,
                        list: "LIVE TV - Last 25 Items",
                        position: "" + position
                    };
                    last20Items.push(last25Item);
                    position = position + 1;
                }

                $liveTvShopLast25.append($tilesGridWrap);
                $("#liveTvShopRecent25").addClass("js-hit-api");
                shopLastEvents();
                updateLastAttributesFromSFCC(settings, response);
                staticImageRender();
                addSlickSlider();
            } else {
                $("#liveTvShopRecent25").removeClass("js-hit-api");
            }
        });
    }

}

/**
 * @description Shop Last Items Renders
 * @returns {void}
 */
function renderLiveTvShopLast25Items() {
    $(window).on("scroll", function () {
        try {
            displayLiveTvShopLast25ItemsNew();
        } catch (err) {
            // continue regardless of error
        }

        displayLiveTvShopLast20Items();
    }).scroll();
}

function addSlickSlider() {
    //--Slick Slider With custom buttons
    var backButton = "<span class=\"slick-prev\"><i class=\"fa fa-angle-left\"></i></span>";
    var nextButton = "<span class=\"slick-next\"><i class=\"fa fa-angle-right\"></i></span>";
    $(".shop-last-grid").slick({
        dots: false,
        arrow: true,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        prevArrow: backButton,
        nextArrow: nextButton,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "100px"
                }
            }
        ]
    });
}

/**
 * @description Recently On Air Items
 * @returns {void}
 */
function displayRecentlyOnAirItems() {
    var recentlyOnAirItems = $(".hourlyDataTag").attr("data-items");
    if (recentlyOnAirItems) {
        renderLiveTvShopLast24HoursItems(JSON.parse(recentlyOnAirItems));
    }


    $(document).on("click", ".dateTabs .changeDate, .hourTabs .changeHour", function (e) {
        e.preventDefault();
        var $this = $(this);
        var owlSlideIndex = $this.parent().attr("data-index");

        //console.log(owlSlideIndex);

        if ($this.hasClass("disabled")) {
            return;
        }
        $.spinner().start();
        $.ajax({
            url: $(this).closest(".on-air").data("url"),
            method: "GET",
            dataType: "html",
            data: {
                date: $this.data("date"),
                time: $this.data("time"),
                timeZone: $(".timeZone #selectedTimeZone").val()
            },
            success: function (response) {
                // console.log(response);
                $this.closest(".on-air").html($(response).find(".on-air").html());
                require("../home/carousel").init();

                $("body").trigger("wishlist:update_icon");

                var dataTag = $(".hourlyDataTag");
                var recentlyOnAirItems = JSON.parse(dataTag.attr("data-items"));
                renderLiveTvShopLast24HoursItems(recentlyOnAirItems);
                $(".onair-carousel").trigger("to.owl.carousel", owlSlideIndex);
            },
            error: function () {
                window.location.reload();
            }
        });
        $.spinner().stop();
    });

}

function renderHomepageLiveTvShopLast25Items() {

    var settings = $("#shopLastSettings").data();

    if (typeof settings === "undefined") {
        return;
    }

    $(document).ready(function () {
        //var scrollDistance = $(window).scrollTop() + $(window).height();
        //var footerOffset = $(".live-tv-shop-recent-section").offset();

        //var footerDistance = footerOffset !== undefined ? $(".live-tv-shop-recent-section").offset().top : 0;
        var stopAPICall = $("#liveTvShopRecent25home").hasClass("js-hit-api");
        //if (scrollDistance >= footerDistance) {
        if (stopAPICall) {
            $("#liveTvShopRecent25home").removeClass("js-hit-api");
            offset = offset + 25;
            $.ajax({
                url: settings.shopLast25ItemsControllerUrl + "?offSet=" + offset + "&limit=" + limit,
                dataType: "json",
                method: "GET",
                success: function (data) {
                    if (!data.success) {
                        $("#liveTvShopRecent25home").removeClass("recently-on-air-products-height");
                        console.log("success false");
                    } else {
                        var shopLast25Itemsdata = data.shopLast25Items;
                        if (data.shopLast25Items[0].stockListDetail.length == 0) {
                            $("#liveTvShopRecent25home").removeClass("recently-on-air-products-height");
                        }
                        shopLast25Itemsdata.forEach(function (shopLast25Item) {
                            renderLiveTvShopLast25ItemsHomepage(shopLast25Item.stockListDetail);
                        });

                    }
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
        //}

    });

}

function renderLiveTvShopLast25ItemsHomepage(shopLast24hItems) {
    var settings = $("#shopLastSettings").data();
    if (typeof settings === "undefined") {
        return;
    }
    var $liveTvShopLast24Grid = $("#liveTvShopRecent25home");
    //var $tilesGridWrap = $("<div/>", { class: "product-grid shop-last25items shop-last-grid d-flex flex-wrap" });
    var $tilesGridWrap = $("<div/>", { class: "product-grid shop-last-grid d-flex flex-wrap" });
    var position = 1;
    shopLast24hItems.forEach(function (item) {
        var productId = item.stockCode;
        var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
        path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
        var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
        var baseProdURLObj = new URL(baseProdURL);
        baseProdURLObj.searchParams.append("w", 300);
        baseProdURLObj.searchParams.append("h", 300);
        var productImageUrl = baseProdURLObj.href.toString();
        var productSecondImageUrl = productImageUrl.split(".jpg");
        if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
            productSecondImageUrl = productImageUrl;
        } else {
            productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
            productSecondImageUrl = productSecondImageUrl.join(".jpg");
        }

        // const productSecondImageUrl = settings.thumbSecondImageUrl.replace("{path}", path) .replace("{position}", 1 ).replace("{position}", 1 );

        var currentPrice = item.price;
        var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
        var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

        var itemObj = {
            productid: productId,
            productname: item.name,
            productImageUrl: productImageUrl,
            productSecondImageUrl: productSecondImageUrl,
            currentprice: currentPrice,
            estRetValueVal: estRetValueVal,
            estSavingsVal: estSavingsVal,
            emicount: item.emicount,
            itemType: "24",
            select1: item.singleVariants,
            select2: item.multiVariants,
            sizes : item.multiVariants,
            SkuProductId: item.groupNames,
            isMLA: item.isMLA,
            isGroupAuction: item.isGroupAuction,
            isBuyAll: item.isBuyAll,
            buyAllDiscount: item.buyAllDiscount,
            buyAllPrice: item.buyAllPrice,
            shippingCharges: item.shippingCharges,
            actualAuctionEndTime: item.actualAuctionEndTime,
            isAvailable: item.isAvailable
        };

        $tilesGridWrap.append(renderShopLastItem(itemObj, settings));
        $liveTvShopLast24Grid.append($tilesGridWrap);
        $("#liveTvShopRecent25home").addClass("js-hit-api");

        var last25Item = {
            id: productId,
            name: item.productname,
            price: currentPrice,
            list: "LIVE TV - Last 25 Items",
            position: "" + position
        };
        last25Items.push(last25Item);
        position = position + 1;
    });

    startCountdown();
    shopLastEvents();
    staticImageRender();
    addSlickSlider();
    setTimeout(function () {
        if (last25Items.length > 0) {
            var noOfImpressions = 0;
            last25Items.forEach(function (LTLastItem) {
                gaHelper.fireGAEvent("AddListItem", LTLastItem);
                noOfImpressions++;
                if (noOfImpressions == 20) {
                    noOfImpressions = 0;
                    gaHelper.fireGAEvent("FireListItem");
                }
            });
            if (noOfImpressions % 20 != 0) {
                gaHelper.fireGAEvent("FireListItem");
            }
            last25Items = [];
        }
    }, 5000);
}
/**
 * Build Shop Last Product Tile HTML SHOP Last Item
 */
function renderShopLastItem(item, settings) {
    var productUrl = settings.productUrl + item.productid;
    var quickViewUrl = settings.quickViewUrl + item.productid;
    var wishlistUrl = settings.wishlistUrl + item.productid;
    var wishlistRemoveUrl = settings.removeWishlistUrl + item.productid;
    var isFastBuyEnabled = settings.isFastBuyEnabled;
    var addToCartWrapperClass =  "fast-buy-present";
    var fastBuyWrapperClass =  "col-6" ;
    var fastBuyUrl = settings.fastBuyUrl;

    var bpHtml = "";
    if (item.emicount > 0) {
        var bpInstNumber = item.emicount;
        var bpInstAmount = (parseFloat(item.currentprice) / bpInstNumber).toFixed(2);
        var budgetPayImgURL = settings.budgetPayLogoImgSrc;
        if (isCRO) {
            budgetPayImgURL = settings.budgetPayLogoImgSrcBlack;
        } else {
            budgetPayImgURL = settings.budgetPayLogoImgSrc;
        }
        bpHtml = "<div class=\"product-current-price bp-price d-flex flex-wrap\">" +
            "<img class=\"lazyload bp-logo\" data-src=\"" + budgetPayImgURL + "\" />" +
            `<div class="bp-value" data-budget-pay data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}>` +
            "<span class=\"item-installments-number\">" + bpInstNumber + "</span>" + "x" +
            "<span class=\"item-installments-amount\">" +
            settings.currencySymbol + "<span>" + bpInstAmount + "</span> payments*" +
            "</span>" +
            "</div>" +
            "<div class=\"alert tooltip-popup alert-dismissible fade d-none\" role=\"alert\">" +
            "<p>" + settings.bpTooltipMsg + "</p>" +
            "<button type=\"button\" class=\"close budget-pay-button\" aria-label=\"close\">" +
            "<span class=\"fa fa-times\" aria-hidden=\"true\"></span>" +
            "</button>" +
            "</div>" +
            "</div>";
    }

    var productTileWrapper = "";
    var budgetPayItem = "";
    var soldOutBadge = "";

    if (bpInstNumber && bpInstAmount) {
        productTileWrapper = `<div class="product-tile-wrapper shop-last-tile ${item.actualAuctionEndTime > 0 && item.isAvailable ? "auction-timer": ""} product" data-product-id="${item.productid}" data-pid="${item.productid}" data-pname="${item.productname}" data-itemtype="${item.itemType}" data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}>`;
        budgetPayItem = `<div class="position-absolute" data-budget-pay data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}></div>`;
    } else {
        productTileWrapper = `<div class="product-tile-wrapper shop-last-tile ${item.actualAuctionEndTime > 0 && item.isAvailable ? "auction-timer": ""} product" data-product-id="${item.productid}" data-pid="${item.productid}" data-pname="${item.productname}" data-itemtype="${item.itemType}">`;
    }

    if (!item.isAvailable) {
        soldOutBadge = "<span class=\"sold-out-badge\" style=\"display:inline\">" + settings.soldLabel + "</span>";
    } else {
        soldOutBadge = "<span class=\"sold-out-badge\">" + settings.soldLabel + "</span>";
    }

    var link = window.location.origin + "/" + item.productid + ".html";
    var peObj = {
        pid: item.productid,
        pname: item.productname,
        actualprice: item.currentprice ? item.currentprice.toString() : "",
        pprice: item.estRetValueVal,
        link: link,
        imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
        bigimageurl: item.productImageUrl,
        availability: "outOfStock"
    };
    var peObjJSONString = JSON.stringify(peObj);
    var productids = [];

    if (item.isBuyAll && !item.isMLA && item.isGroupAuction) {
        productids = item.select1.filter(item => item.isAvailable).map(item => item.productId);
    }

    var tileHtml = "<!-- Shop Last 25 Items Tile -->" +
            productTileWrapper +
            "<div class=\"product-tile d-flex justify-content-between CDFR\">" +
            budgetPayItem +
            "<div class=\"tile-badges-image\">" +
            "<div class=\"wishlist-air-container\">" +
            "<button class=\"btn wishlistTile custom-add-wishlist-btn wl-toggle\" data-action=\"" + wishlistUrl + "\" title=\"\">" +
            "<span class=\"wishlist-icon\">" +
            "<i class=\"fa fa-heart\"></i>" +
            "</span>" +
            "</button>" +
            "<button class=\"btn remove-wishlist-btn wl-toggle d-none\" data-url=\"" + wishlistRemoveUrl + "\" title=\"\">" +
            "<span class=\"wishlist-icon\">" +
            "<i class=\"fa fa-heart wishlistRed\"></i>" +
            "</span>" +
            "</button>" +
            "<span class=\"leading-value-badge\">" + settings.todayLeadingValueLabel + "</span>" +
            "<span class=\"sold-out-badge\">" + settings.soldLabel + "</span>" +
            "</div>" +
            "<div class=\"image-container\">" +
            "<a href=\"" + productUrl + "\">" +
            "<img class=\"lazyload tile-image productTileImage\" " +
            "data-src=\"" + item.productImageUrl + "\" " +
            "data-secondary-image-src=\"" + item.productSecondImageUrl + "\" " +
            "data-image-src=\"" + item.productImageUrl + "\" " +
            "alt=\""+item.productname+"\" " +
            "title=\""+item.productname+"\" " +
            "/>" +
            "</a>" +
            "</div>" +
            "</div>" +

            "<div class=\"actions flex-column justify-content-center align-items-center\">" +
            "<div class=\"d-flex actions-row mt-2 \">" +
            "<a class=\"quickview\" " +
            "data-toggle=\"modal\" " +
            "data-target=\"#quickViewModal\" " +
            "title=\"" + settings.quickViewTitle + item.productname + "\" " +
            "aria-label=\"" + settings.quickViewTitle + item.productname + "\" " +
            "data-url=\"" + quickViewUrl + "\">" +
            "<span class=\"tile-quick-view\">" + settings.quickViewBtn + "</span>" +
            "</a>" +
            "</div>" +
            "<div class=\"d-flex actions-row actions-wrapper m-0 \">" +
            `<div class="text-center cart-wrapper ${addToCartWrapperClass}">` +
            "<a class=\"add-to-cart\">" + settings.addToCartBtn + "</a>" +
            "</div>" +
            "<div class=\"text-center fastbuy-wrapper fast-buy\">" +
            `<a class="link p-0 fast-buy-button" data-url="${fastBuyUrl}">` +
            "<span class=\"fast-buy-button-inner\">" +
            settings.fastBuyLabel +
            "</span>" +
            "<i class=\"fa fa-forward\"></i>" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>" +

            "<div class=\"tile-body d-flex flex-wrap\">" +
            "<div class=\"tile-details flex-grow-1\">" +
            "<div class=\"product-name d-flex flex-wrap flex-grow-1\">" +
            "<a class=\"product-name-link\" href=\"" + productUrl + "\">" + item.productname + "</a>" +
            "<a class=\"action-link view-item quickview\" " +
            "data-target=\"#quickViewModal\" " +
            "data-toggle=\"modal\" " +
            "data-url=\"" + quickViewUrl + "\">" +
            "<span class=\"fa fa-eye\" aria-hidden=\"true\"></span>" +
            "<span class=\"tile-quick-view\">" + settings.viewItemBtn + "</span>" +
            "</a>" +
            "</div>" +
            "<div class=\"product-id\">" + item.productid + "</div>" +
            "<div class=\"shoplast-shipping-charges d-none\" data-shippingcharges=\"" + item.shippingCharges + "\">" + "</div>" +
            "<div class=\"more-options-msg\">" + settings.moreOptionsLabel + "</div>" +

            "<div class=\"product-prices d-flex flex-wrap\">" +
            "<div class=\"product-current-prices\">" +
            "<div class=\"product-current-price shoplc-price\">" +
            "<span class=\"shoplc-price-label\">" + settings.shoplcPriceLabel + "</span>" +
            "<span class=\"shoplc-price-value js-formatted-price\">" + settings.currencySymbol + item.currentprice + "</span>" +
            "</div>" +
            bpHtml +
            "</div>" +

            "<div class=\"estimated-values text-left flex-wrap justify-content-around\">" +
            "<div class=\"estprice-wrapper\">" +
            "<span class=\"estprice-label d-block\">" + settings.estRetValueLabel + "</span>" +
            "<del class=\"estprice-value d-block\">" + settings.currencySymbol + item.estRetValueVal + "</del>" +
            "</div>" +
            "<div class=\"save-wrapper\">" +
            "<span class=\"save-label d-block\">" + settings.estSavingsLabel + "</span>" +
            "<span class=\"red-text save-value d-block\">" + item.estSavingsVal + "%</span>" +
            "</div>" +
            "</div>" +

            "<div class=\"action-buttons-wrap flex-grow-1 flex-wrap justify-content-end\">" +
                `<div class="action-buttons ${isFastBuyEnabled ? "fast-buy-active" : ""}">` +
                    `<a class="action-link cart-wrapper add-to-cart ${item.isAvailable ? "" : " d-none"}">` +
                        "<span class=\"fa fa-shopping-cart\" aria-hidden=\"true\"></span>" +
                        "<span class=\"\">" + settings.addToCartBtn + "</span>" +
                    "</a>" +
                    `<div class="text-center fastbuy-wrapper fast-buy ${fastBuyWrapperClass} ${item.isAvailable ? "" : " d-none"}">` +
                        `<a class="link fast-buy-button" data-url="${fastBuyUrl}">` +
                            "<span class=\"fast-buy-button-inner\">" +
                                settings.fastBuyLabel +
                            "</span>" +
                            "<i class=\"fa fa-forward\"></i>" +
                        "</a>" +
                    "</div>" +
                    `<div class="notify-me-btn ${item.isAvailable ? " d-none" : ""}">
                        <button type="button" class="btn btn-block livetv-submit-notifyme" id='notify-logout' data-pid="${item.productid}" data-productdetails='${peObjJSONString}'>
                            <i class="fa fa-bell-o font-weight-bolder mr-2"></i>
                            NOTIFY ME
                        </button>
                    </div>`+
                "</div>" +
            "</div>" +

            "</div>" +

            "</div>" +

            "</div>";
            
    var itemsHtml = "<!-- Shop 24 Hours Items Tile -->" +
            productTileWrapper +
            `<div class="auction-timer-section ${item.actualAuctionEndTime > 0 && item.isAvailable ? "": "d-none"}">`+
            `<img class="lazyload timer" data-src=${settings.alarmClock}/>`+
            `<div class="countdown counting" data-end-date=${item.actualAuctionEndTime}></div></div>` +
            "<div class=\"product-tile d-flex\">" +
                budgetPayItem +
                "<div class=\"tile-badges-image\">" +
                    "<div class=\"wishlist-air-container\">" +
                        "<button class=\"btn wishlistTile custom-add-wishlist-btn wl-toggle\" data-action=\"" + wishlistUrl + "\" title=\"\">" +
                            "<span class=\"wishlist-icon\">" +
                                "<i class=\"fa fa-heart\"></i>" +
                            "</span>" +
                        "</button>" +
                        "<button class=\"btn remove-wishlist-btn wl-toggle d-none\" data-url=\"" + wishlistRemoveUrl + "\" title=\"\">" +
                            "<span class=\"wishlist-icon\">" +
                                "<i class=\"fa fa-heart wishlistRed\"></i>" +
                            "</span>" +
                        "</button>" +
                        "<span class=\"leading-value-badge\">" + settings.todayLeadingValueLabel + "</span>" +
                        soldOutBadge +
                    "</div>" +
        
                    "<div class=\"image-container\">" +
                        "<a class=\"product-image-url\" href=\"" + productUrl + "\">" +
                            "<img class=\"lazyload tile-image productTileImage\" " +
                                "data-src=\"" + item.productImageUrl + "\" " +
                                "data-secondary-image-src=\"" + item.productSecondImageUrl + "\" " +
                                "data-image-src=\"" + item.productImageUrl + "\" " +
                                "alt=\"" + item.productname + "\" " +
                                "title=\"" + item.productname + "\" " +
                            "/>" +
                        "</a>" +
                    "</div>" +
                "</div>" +
        
                "<div class=\"actions flex-column justify-content-center align-items-center\">" +
                    "<div class=\"d-flex actions-row mt-2 action-quickview-wrapper\">" +
                        "<a class=\"quickview\" " +
                            "data-toggle=\"modal\" " +
                            "data-target=\"#quickViewModal\" " +
                            "title=\"" + settings.quickViewTitle + item.productname + "\" " +
                            "aria-label=\"" + settings.quickViewTitle + item.productname + "\" " +
                            "data-url=\"" + quickViewUrl + "\">" +
                            "<span class=\"tile-quick-view\">" + settings.quickViewBtn + "</span>" +
                        "</a>" +
                    "</div>" +
        
                    "<div class=\"d-flex actions-row actions-wrapper m-0 shop-items\">" +
                        `<div class="text-center cart-wrapper ${addToCartWrapperClass} ${item.isAvailable ? "" : " d-none"}">` +
                            "<a class=\"add-to-cart\">" + settings.addToCartBtn + "</a>" +
                        "</div>" +
                        `<div class="text-center fastbuy-wrapper fast-buy ${item.isAvailable ? "" : " d-none"}">` +
                            `<a class="link p-0 fast-buy-button" data-url="${fastBuyUrl}">` +
                                "<span class=\"fast-buy-button-inner\">" +
                                    settings.fastBuyLabel +
                                "</span>" +
                                "<i class=\"fa fa-forward\"></i>" +
                            "</a>" +
                        "</div>" +
                        `<div class="notify-me-btn ${item.isAvailable ? " d-none" : ""}">
                            <button type="button" class="btn btn-block livetv-submit-notifyme" id='notify-logout' data-pid="${item.productid}" data-productdetails='${peObjJSONString}'>
                                <i class="fa fa-bell-o font-weight-bolder mr-2"></i>
                                NOTIFY ME
                            </button>
                        </div>` +
                    "</div>" +
                "</div>" +
        
                "<div class=\"tile-body d-flex flex-wrap\">" +
                "<!--Buttons For Home Page-->" +
                "<div class=\"actions flex-column justify-content-center align-items-center homepageShopLast\">" +
                    "<div class=\"d-flex actions-row mt-2 action-quickview-wrapper\">" +
                        "<a class=\"quickview\" " +
                            "data-toggle=\"modal\" " +
                            "data-target=\"#quickViewModal\" " +
                            "title=\"" + settings.quickViewTitle + item.productname + "\" " +
                            "aria-label=\"" + settings.quickViewTitle + item.productname + "\" " +
                            "data-url=\"" + quickViewUrl + "\">" +
                            "<span class=\"tile-quick-view\">" + settings.quickViewBtn + "</span>" +
                        "</a>" +
                    "</div>" +

                    "<div class=\"d-flex actions-row actions-wrapper m-0 \">" +
                        `<div class="text-center cart-wrapper ${addToCartWrapperClass} ${item.isAvailable ? "" : " d-none"}">` +
                            "<a class=\"add-to-cart\">" + settings.addToCartBtn + "</a>" +
                        "</div>" +

                        `<div class="text-center fastbuy-wrapper fast-buy ${item.isAvailable ? "" : " d-none"}">` +
                            `<a class="link p-0 fast-buy-button" data-url="${fastBuyUrl}">` +
                                "<span class=\"fast-buy-button-inner\">" +
                                    settings.fastBuyLabel +
                                "</span>" +
                                "<i class=\"fa fa-forward\"></i>" +
                            "</a>" +
                        "</div>" +
                        `<div class="notify-me-btn ${item.isAvailable ? " d-none" : ""}">
                        <button type="button" class="btn btn-block livetv-submit-notifyme" id='notify-logout' data-pid="${item.productid}" data-productdetails='${peObjJSONString}'>
                            <i class="fa fa-bell-o font-weight-bolder mr-2"></i>
                            NOTIFY ME
                        </button>
                    </div>` +
                    "</div>" +
                "</div>" +
                    "<div class=\"tile-details flex-grow-1\">" +
                        "<div class=\"product-name d-flex flex-wrap flex-grow-1\">" +
                            "<a class=\"product-name-link\" href=\"" + productUrl + "\">" + item.productname + "</a>" +
                        "</div>" +
                        "<div class=\"product-id\">" + item.productid + "</div>" +
                        "<div class=\"shoplast-shipping-charges d-none\" data-shippingcharges=\"" + item.shippingCharges + "\"></div>" +
                        "<div class=\"more-options-msg\">" + settings.moreOptionsLabel + "</div>" +
                        "<div class=\"product-prices d-flex flex-grow-1\">" +
                            "<div class=\"estimated-values\">" +
                                "<div class=\"estprice-wrapper ml-0\">" +
                                    "<span class=\"estprice-label d-block\">" + settings.estRetValueLabel + "</span>" +
                                    "<del class=\"estprice-value d-block\">" + settings.currencySymbol + item.estRetValueVal + "</del>" +
                                "</div>" +
                                "<div class=\"save-wrapper ml-0\">" +
                                    "<span class=\"save-label d-block saveBtn\">" + settings.estSavingsLabel + "</span>" +
                                    "<span class=\"red-text save-value d-block saveBtnval\">" + item.estSavingsVal + "%</span>" +
                                "</div>" +
                                "<div class=\"action-buttons-wrap checkBtn\">" +
                                    "<div class=\"select-item-container visible-listview\">" +
                                        `<div id="selectOptionVarient" class="d-none">${JSON.stringify(item.sizes)}</div>` +
                                        `<div class="select-item-wrapper">
                                            ${item.isMLA == true && item.isGroupAuction == true ? 
                                                `<select class="select-holder" name="selectOptionVarient">
                                                    <option value="-1">Select Choice</option>
                                                    ${populatSelectData(item.SkuProductId, item.productid)}
                                                </select>
                                                <select class="select-holder" name="sizeOption" id="optionSelector">
                                                    <option class="js-select-label">Select Option</option>
                                                </select>` : 
                                                item.isMLA == false && item.isGroupAuction == true ? 
                                                    `<select class="select-holder" name="singleSelectDropdown">
                                                        <option>Select Option</option>
                                                        ${populatSelectData2(item.select1)}
                                                        ${item.isBuyAll == true && productids.length > 1 ? `<option data-productids="${productids}" data-buyAllDiscount="${item.buyAllDiscount}" data-buyAllPrice="${item.buyAllPrice}" data-emicount="${item.emicount}">Buy All</option>` : ""}
                                                    </select>` : 
                                                    ""
                                            }
                                        </div>` +
                                    "</div>" +
                                    `<div class="action-buttons ${isFastBuyEnabled ? "fast-buy-active" : ""}">` +
                                        `<a class="action-link cart-wrapper add-to-cart w-100 text-center checkAddtoCart ${item.isAvailable ? "" : " d-none"}">` +
                                            "<span class=\"fa fa-shopping-cart\" aria-hidden=\"true\"></span>" +
                                            "<span class=\"\">" + settings.addToCartBtn + "</span>" +
                                        "</a>" +
                                        `<div class="text-center fastbuy-wrapper fast-buy checkfastBuy ${fastBuyWrapperClass} ${item.isAvailable ? "" : " d-none"}">` +
                                            `<a class="link fast-buy-button" data-url="${fastBuyUrl}">` +
                                                "<span class=\"fast-buy-button-inner\">" +
                                                    settings.fastBuyLabel +
                                                "</span>" +
                                                "<i class=\"fa fa-forward\"></i>" +
                                            "</a>" +
                                        "</div>" +
                                        `<div class="notify-me-btn ${item.isAvailable ? " d-none" : ""}">
                                            <button type="button" class="btn btn-block livetv-submit-notifyme" id='notify-logout' data-pid="${item.productid}" data-productdetails='${peObjJSONString}'>
                                                <i class="fa fa-bell-o font-weight-bolder mr-2"></i>
                                                NOTIFY ME
                                            </button>
                                        </div>` +
                                    "</div>" +
                                "</div>" +
                            "</div>" +
                            "<div class=\"product-current-prices checkShoplc\">" +
                                "<div class=\"row\">" +
                                    "<div class=\"product-current-price flex-column shoplc-price col-12\">" +
                                        "<span class=\"shoplc-price-label\">" + settings.shoplcPriceLabel + "</span>" +
                                        "<span class=\"shoplc-price-value js-formatted-price\">" + settings.currencySymbol + item.currentprice + "</span>" +
                                    "</div>" +
                                "</div>" +
                                bpHtml +
                            "</div>" +
                        "</div>" +
                    "</div>" +
        
                    "<div class=\"select-item-container visible-gridview\">" +
                        `<div id="selectOptionVarient2" class="d-none">${JSON.stringify(item.sizes)}</div>` +
                        `<div class="select-item-wrapper">
                            ${item.isMLA == true && item.isGroupAuction == true ? 
                                `<select class="select-holder" name="selectOptionVarient">
                                    <option value="-1">Select Choice</option>
                                    ${populatSelectData(item.SkuProductId, item.productid)}
                                </select>
                                <select class="select-holder" name="sizeOption" id="optionSelector">
                                    <option class="js-select-label">Select Option</option>
                                </select>` : 
                                item.isMLA == false && item.isGroupAuction == true ? 
                                    `<select class="select-holder" name="singleSelectDropdown">
                                        <option>Select Option</option>
                                        ${populatSelectData2(item.select1)}
                                        ${item.isBuyAll == true && productids.length > 1 ? `<option data-productids="${productids}" data-buyAllDiscount="${item.buyAllDiscount}" data-buyAllPrice="${item.buyAllPrice}" data-emicount="${item.emicount}">Buy All</option>` : ""}
                                    </select>` : 
                                    ""
                            }
                        </div>` +
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

       
    if (item.itemType == "24") {
        return itemsHtml;
    }
    else {
        return tileHtml;
    }
}

/**
 * Build Shop Last Product Tile HTML - Rencently Aired Item
 */
function renderRecentAiredItem(item, settings) {
    var productUrl = settings.productUrl + item.productid;
    var quickViewUrl = settings.quickViewUrl + item.productid;
    var wishlistUrl = settings.wishlistUrl + item.productid;
    var wishlistRemoveUrl = settings.removeWishlistUrl + item.productid;
    var isFastBuyEnabled = settings.isFastBuyEnabled;
    var addToCartWrapperClass = "col-6 fast-buy-present" ;
    /* eslint-disable no-unused-vars */
    var fastBuyWrapperClass ="col-6";
    /* eslint-enable no-unused-vars */
    var fastBuyUrl = settings.fastBuyUrl;

    var bpHtml = "";
    if (item.emicount > 0) {
        var bpInstNumber = item.emicount;
        var bpInstAmount = (parseFloat(item.currentprice) / bpInstNumber).toFixed(2);

        bpHtml = "<div class=\"product-current-price bp-price d-flex flex-wrap\">" +
            "<img class=\"lazyload bp-logo\" data-src=\"" + settings.budgetPayLogoImgSrc + "\" />" +
            `<div class="bp-value" data-budget-pay data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}>` +
            "<span class=\"item-installments-number\">" + bpInstNumber + "</span>" + "x" +
            "<span class=\"item-installments-amount\">" +
            settings.currencySymbol + "<span>" + bpInstAmount + "</span> payments*" +
            "</span>" +
            "</div>" +
            "<div class=\"alert tooltip-popup alert-dismissible fade d-none\" role=\"alert\">" +
            "<p>" + settings.bpTooltipMsg + "</p>" +
            "<button type=\"button\" class=\"close budget-pay-button\" aria-label=\"close\">" +
            "<span class=\"fa fa-times\" aria-hidden=\"true\"></span>" +
            "</button>" +
            "</div>" +
            "</div>";
    }

    var productTileWrapper = "";
    var budgetPayItem = "";

    if (bpInstNumber && bpInstAmount) {
        productTileWrapper = `<div class="product-tile-wrapper shop-last-tile product" data-product-id="${item.productid}" data-pid="${item.productid}" data-pname="${item.productname}" data-itemtype="${item.itemType}" data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}>`;
        budgetPayItem = `<div class="position-absolute" data-budget-pay data-installments-number=${bpInstNumber} data-installments-amount=${bpInstAmount}></div>`;
    } else {
        productTileWrapper = `<div class="product-tile-wrapper shop-last-tile product" data-product-id="${item.productid}" data-pid="${item.productid}" data-pname="${item.productname}" data-itemtype="${item.itemType}">`;
    }

    var link = window.location.origin + "/" + item.productid + ".html";
    var peObj = {
        pid: item.productid,
        pname: item.productname,
        actualprice: item.currentprice ? item.currentprice.toString() : "",
        pprice: item.estRetValueVal,
        link: link,
        imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
        bigimageurl: item.productImageUrl,
        availability: "outOfStock"
    };
    var peObjJSONString = JSON.stringify(peObj);

    var itemsHtml = "<!-- Shop 24 Hours Items Tile -->" +
            productTileWrapper +
            "<div class=\"product-tile d-flex\">" +
            budgetPayItem +
            "<div class=\"tile-badges-image\">" +
            "<div class=\"wishlist-air-container\">" +
            "<button class=\"btn wishlistTile custom-add-wishlist-btn wl-toggle\" data-action=\"" + wishlistUrl + "\" title=\"\">" +
            "<span class=\"wishlist-icon\">" +
            "<i class=\"fa fa-heart\"></i>" +
            "</span>" +
            "</button>" +
            "<button class=\"btn remove-wishlist-btn wl-toggle d-none\" data-url=\"" + wishlistRemoveUrl + "\" title=\"\">" +
            "<span class=\"wishlist-icon\">" +
            "<i class=\"fa fa-heart wishlistRed\"></i>" +
            "</span>" +
            "</button>" +
            "<span class=\"leading-value-badge\">" + settings.todayLeadingValueLabel + "</span>" +
            "<span class=\"sold-out-badge\">" + settings.soldLabel + "</span>" +
            "</div>" +
            "<div class=\"image-container\">" +
            "<a href=\"" + productUrl + "\">" +
            "<img class=\"lazyload tile-image productTileImage\" " +
            "data-src=\"" + item.productImageUrl + "\" " +
            "data-secondary-image-src=\"" + item.productSecondImageUrl + "\" " +
            "data-image-src=\"" + item.productImageUrl + "\" " +
            "alt=\"" + item.productname + "\" " +
            "title=\"" + item.productname + "\" " +
            "/>" +
            "</a>" +
            "</div>" +
            "</div>" +
            "<div class=\"tile-body d-flex flex-wrap\">" +
            "<div class=\"tile-details flex-grow-1\">" +
            "<div class=\"product-name d-flex flex-wrap flex-grow-1\">" +
            "<a class=\"product-name-link\" href=\"" + productUrl + "\">" + item.productname + "</a>" +

            "</div>" +
            "<div class=\"product-id\">" + item.productid + "</div>" +
            "<div class=\"more-options-msg\">" + settings.moreOptionsLabel + "</div>" +

            "<div class=\"product-prices d-flex flex-column\">" +

            "<div class=\"estimated-values text-left justify-content-between row\">" +
            "<div class=\"estprice-wrapper ml-0 col-sm-4 col-md-4\">" +
            "<span class=\"estprice-label d-block\">" + settings.estRetValueLabel + "</span>" +
            "<del class=\"estprice-value d-block\">" + settings.currencySymbol + item.estRetValueVal + "</del>" +
            "</div>" +
            "<div class=\"save-wrapper ml-0 col-sm-3 col-md-8\">" +
            "<span class=\"save-label d-block saveBtn\">" + settings.estSavingsLabel + "</span>" +
            "<span class=\"red-text save-value d-block saveBtnval\">" + item.estSavingsVal + "%</span>" +
            "</div>" +
            "<div class=\"action-buttons-wrap col-sm-5 col-md-7 offset-md-7 checkBtn\">" +
                `<div class="action-buttons ${isFastBuyEnabled ? "fast-buy-active" : ""}">` +
                    `<a class="action-link cart-wrapper add-to-cart w-100 text-center checkAddtoCart ${item.isAvailable ? "" : " d-none"}">` +
                        "<span class=\"fa fa-shopping-cart\" aria-hidden=\"true\"></span>" +
                        "<span class=\"\">" + settings.addToCartBtn + "</span>" +
                    "</a>" +
                    `<div class="text-center fastbuy-wrapper fast-buy checkfastBuy
                    ${fastBuyWrapperClass} ${item.isAvailable ? "" : " d-none"}">` +
                        `<a class="link fast-buy-button" data-url="${fastBuyUrl}">` +
                            "<span class=\"fast-buy-button-inner\">" +
                                settings.fastBuyLabel +
                            "</span>" +
                            "<i class=\"fa fa-forward\"></i>" +
                        "</a>" +
                    "</div>" +
                    `<div class="notify-me-btn ${item.isAvailable ? " d-none" : ""}">
                        <button type="button" class="btn btn-block livetv-submit-notifyme" id='notify-logout' data-pid="${item.productid}" data-productdetails='${peObjJSONString}'>
                            <i class="fa fa-bell-o font-weight-bolder mr-2"></i>
                            NOTIFY ME
                        </button>
                    </div>`+
                "</div>" +
            "</div>" +
            "</div>" +



            "<div class=\"product-current-prices checkShoplc\">" +
            "<div class=\"row\">" +
            "<div class=\"product-current-price flex-column shoplc-price col-sm-4 col-md-12\">" +
            "<span class=\"shoplc-price-label\">" + settings.shoplcPriceLabel + "</span>" +
            "<span class=\"shoplc-price-value js-formatted-price\">" + settings.currencySymbol + item.currentprice + "</span>" +
            "</div>" +



            "</div>" +
            bpHtml +
            "</div>" +
            "</div>" +

            "</div>" +

            "<div class=\"actions flex-column justify-content-center align-items-center\">" +
            "<div class=\"d-flex actions-row mt-2 \">" +
            "<a class=\"quickview\" " +
            "data-toggle=\"modal\" " +
            "data-target=\"#quickViewModal\" " +
            "title=\"" + settings.quickViewTitle + item.productname + "\" " +
            "aria-label=\"" + settings.quickViewTitle + item.productname + "\" " +
            "data-url=\"" + quickViewUrl + "\">" +
            "<span class=\"tile-quick-view\">" + settings.quickViewBtn + "</span>" +
            "</a>" +
            "</div>" +
            "<div class=\"d-flex actions-row actions-wrapper m-0 \">" +
            `<div class="text-center cart-wrapper ${addToCartWrapperClass}">` +
            "<a class=\"add-to-cart\">" + settings.addToCartBtn + "</a>" +
            "</div>" +
            "<div class=\"text-center fastbuy-wrapper fast-buy\">" +
            `<a class="link p-0 fast-buy-button" data-url="${fastBuyUrl}">` +
            "<span class=\"fast-buy-button-inner\">" +
            settings.fastBuyLabel +
            "</span>" +
            "<i class=\"fa fa-forward\"></i>" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>" +

            "</div>" +
            "</div>" +
            "</div>";


    if (item.itemType == "20") {
        return itemsHtml;
    }
}

function populatSelectData(options, productId) {
    var str = "";
    options.map(function (elem) {
        str += `
            <option value="${productId}">${elem}</option>
        `;
    });
    return str;
}

function populatSelectData2(options) {
    var str = "";
    options.map(function (elem) {
        // SIRV Image URL Creation
        var productId = elem.productId;
        var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
        path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
        var settings = $("#shopLastSettings").data();
        var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
        var baseProdURLObj = new URL(baseProdURL);
        baseProdURLObj.searchParams.append("w", 300);
        baseProdURLObj.searchParams.append("h", 300);
        var productImageUrl = baseProdURLObj.href.toString();
        var productSecondImageUrl = productImageUrl.split(".jpg");
        if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
            productSecondImageUrl = productImageUrl;
        } else {
            productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
            productSecondImageUrl = productSecondImageUrl.join(".jpg");
        }
        str += `
        <option value="${elem.productId}" data-isavailable="${elem.isAvailable}" data-itememicount=${elem.emicount} data-itemprice="${elem.price}" data-itemname="${elem.itemName}" data-itemimageurl="${productImageUrl}" data-itemsecondimageurl="${productSecondImageUrl}">${elem.bText}</option>
        `;
    });
    return str;
}

var labelText;
$(document).ready(function () {
    var element = document.getElementById("optionSelector");
    //labelText = $(' option.js-select-label').text();
    labelText = $(element).find("option.js-select-label").text();
});

//var labelText = "Select Size";

// 1st select dropdown
$(document).on("change", "select[name='selectOptionVarient']", function () {
    var $currentSelector = $(this);
    var $currentVarientText = $(this).find("option:selected").text();
    //var varientID = $currentSelector.val();
    //var labelText = $currentSelector.next().find("option:first-child").text();
    if (labelText == null || labelText == "") {
        var element = document.getElementById("optionSelector");
        labelText = $(element).find("option.js-select-label").text();
    }
    if ($currentSelector.val() == -1) {
        // $currentSelector.next().empty();
        $currentSelector.next().html(`<option value="">${labelText}</option>`);
        return;
    }

    //GET select Data
    var getSelectOptionData = $(this).parents(".product-tile").find("#selectOptionVarient").text();
    getSelectOptionData = JSON.parse(getSelectOptionData);
    getSelectOptionData = getSelectOptionData[$currentVarientText];

    var options = `<option value="">${labelText}</option>`;
    for (var i=0; i < getSelectOptionData.length; i++) {
        var varientOption = getSelectOptionData[i];

        if (varientOption.productId && varientOption.bText) {
            //SIRV Image URL Creation
            var productId = varientOption.productId;
            var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
            path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
            var settings = $("#shopLastSettings").data();
            var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
            var baseProdURLObj = new URL(baseProdURL);
            baseProdURLObj.searchParams.append("w", 300);
            baseProdURLObj.searchParams.append("h", 300);
            var productImageUrl = baseProdURLObj.href.toString();
            var productSecondImageUrl = productImageUrl.split(".jpg");
            if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
                productSecondImageUrl = productImageUrl;
            } else {
                productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
                productSecondImageUrl = productSecondImageUrl.join(".jpg");
            }
            options +=
            `<option value="${varientOption.productId}" data-isavailable="${varientOption.isAvailable}" data-itememicount=${varientOption.emicount} data-itemprice=${varientOption.price} data-itemname="${varientOption.itemName}" data-itemimageurl="${productImageUrl}" data-itemsecondimageurl="${productSecondImageUrl}">${varientOption.bText}</option>`;
        }
    }
    $currentSelector.next().html(options);
});

//2nd select dropdown
$(document).on("change", "select[name='sizeOption']", function () {
    var $currentSelector = $(this);
    var varientID = $currentSelector.val();
    if (varientID == "Select Option") {
        return;
    }
    var settings = $("#shopLastSettings").data();
    var auctionEndTime = $(this).parents(".shop-last-tile").find(".auction-timer-section .countdown").data("end-date");
    var isAvailable = $currentSelector.children("option:selected").data("isavailable");
    var itemName = $currentSelector.children("option:selected").data("itemname");
    var itemPrice = $currentSelector.children("option:selected").data("itemprice");
    var itemEmicount = $currentSelector.children("option:selected").data("itememicount");
    var bpInstAmount = itemEmicount > 0 ? (parseFloat(itemPrice) / itemEmicount).toFixed(2) : 0;
    var itemImageUrl = $currentSelector.children("option:selected").data("itemimageurl");
    var itemsecondimageurl = $currentSelector.children("option:selected").data("itemsecondimageurl");
    if (isAvailable) {
        $(this).parents(".product-tile").find(".sold-out-badge").hide();
        $(this).parents(".product-tile").find(".cart-wrapper").removeClass("d-none");
        $(this).parents(".product-tile").find(".fastbuy-wrapper").removeClass("d-none");
        $(this).parents(".product-tile").find(".notify-me-btn").addClass("d-none");
        if (auctionEndTime > 0) {
            $(this).parents(".shop-last-tile").find(".auction-timer-section").removeClass("d-none");
        }
    } else {
        $(this).parents(".product-tile").find(".sold-out-badge").show();
        $(this).parents(".product-tile").find(".cart-wrapper").addClass("d-none");
        $(this).parents(".product-tile").find(".fastbuy-wrapper").addClass("d-none");
        $(this).parents(".product-tile").find(".notify-me-btn").removeClass("d-none");
        $(this).parents(".shop-last-tile").find(".auction-timer-section").addClass("d-none");
    }
    //URL change for product link
    var productURLUpdated = $(this).parents(".product-tile").find(".product-name-link").attr("href");

    var endPoint = productURLUpdated.split("?")[0];
    endPoint = endPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".product-name-link").attr("href", endPoint);

    //URL change for wishlist icon
    var wishlistURLUpdated = $(this).parents(".product-tile").find(".wishlistTile").attr("data-action");
    var wishlistEndPoint = wishlistURLUpdated.split("?")[0];
    wishlistEndPoint = wishlistEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".wishlistTile").attr("data-action", wishlistEndPoint);

    //URL change for remove wishlist icon
    var remWishlistURLUpdated = $(this).parents(".product-tile").find(".remove-wishlist-btn").attr("data-url");
    var remWishlistEndPoint = remWishlistURLUpdated.split("?")[0];
    remWishlistEndPoint = remWishlistEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".remove-wishlist-btn").attr("data-url", remWishlistEndPoint);

    //URL change for product image
    var productImageURLUpdated = $(this).parents(".product-tile").find(".product-image-url").attr("href");
    var productImageEndPoint = productImageURLUpdated.split("?")[0];
    productImageEndPoint = productImageEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".product-image-url").attr("href", productImageEndPoint);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("src", itemImageUrl);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("data-secondary-image-src", itemsecondimageurl);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("data-image-src", itemImageUrl);

    //Product Title change
    $(this).parents(".product-tile").find(".product-name-link").html(itemName);

    //Product Price change
    $(this).parents(".product-tile").find(".shoplc-price-value").html(settings.currencySymbol+itemPrice);

    //Product BP Details change
    if (itemEmicount > 0) {
        $(this).parents(".product-tile").find(".bp-price").addClass("d-flex");
        $(this).parents(".product-tile").find(".bp-price").addClass("flex-wrap");
        $(this).parents(".product-tile").find(".bp-price").removeClass("d-none");
        $(this).parents(".product-tile").find(".item-installments-number").html(itemEmicount);
        $(this).parents(".product-tile").find(".item-installments-amount").html(settings.currencySymbol+bpInstAmount+" PAYMENTS*");
    } else {
        $(this).parents(".product-tile").find(".bp-price").addClass("d-none");
        $(this).parents(".product-tile").find(".bp-price").removeClass("d-flex");
        $(this).parents(".product-tile").find(".bp-price").removeClass("flex-wrap");
    }

    //Product SKU change
    $(this).parents(".product-tile").find(".product-id").html(varientID);

    //URL change for quick view button
    var quickViewURLUpdated = $(this).parents(".product-tile").find(".quickview").attr("data-url");
    var quickViewEndPoint = quickViewURLUpdated.split("?")[0];
    quickViewEndPoint = quickViewEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".quickview").attr("data-url", quickViewEndPoint);

    //URL change for buy now and fast buy buttons
    $(this).parents(".shop-last-tile").attr("data-product-id", varientID);
    $(this).parents(".shop-last-tile").attr("data-pid", varientID);

    if (!isAvailable) {
        var notifyMeCtrl = $(this).parents(".product-tile").find(".livetv-submit-notifyme");
        var peObjJSONString = $(notifyMeCtrl).data("productdetails");
        var link = window.location.origin + "/" + varientID + ".html";
        var productDetObj;
        if ((typeof peObjJSONString === "string" || peObjJSONString instanceof String) && peObjJSONString != "") {
            productDetObj = JSON.parse(peObjJSONString);
        } else {
            productDetObj = peObjJSONString;
        }
        productDetObj.pid = varientID;
        productDetObj.pname = itemName;
        productDetObj.actualprice = itemPrice ? itemPrice.toString() : productDetObj.actualprice;
        productDetObj.link = link;
        productDetObj.bigimageurl = itemImageUrl;
        peObjJSONString = JSON.stringify(productDetObj);
        $(notifyMeCtrl).attr("data-productdetails", peObjJSONString);
        $(notifyMeCtrl).data("productdetails", peObjJSONString);
        $(notifyMeCtrl).attr("data-pid", varientID);
        $(notifyMeCtrl).data("data", varientID);
    }
});

//Single select dropdown
$(document).on("change", "select[name='singleSelectDropdown']", function () {
    var $currentSelector = $(this);
    var varientID = $currentSelector.val();
    if (varientID == "Select Option") {
        return;
    }
    var settings = $("#shopLastSettings").data();
    var auctionEndTime = $(this).parents(".shop-last-tile").find(".auction-timer-section .countdown").data("end-date");
    if (varientID == "Buy All") {
        if ($currentSelector.find("option:selected").data("productids") !== "") {
            var buyallPrice = $currentSelector.find("option:selected").data("buyallprice");
            var buyallEmicount = $currentSelector.find("option:selected").data("emicount");

            $(this).parents(".product-tile").find(".cart-wrapper").removeClass("d-none");
            $(this).parents(".product-tile").find(".fastbuy-wrapper").removeClass("d-none");
            $(this).parents(".product-tile").find(".notify-me-btn").addClass("d-none");
            $(this).parents(".product-tile").find(".shoplc-price-value").html(settings.currencySymbol+buyallPrice);
            $(this).parents(".product-tile").find(".sold-out-badge").hide();
            if (auctionEndTime > 0) {
                $(this).parents(".shop-last-tile").find(".auction-timer-section").removeClass("d-none");
            }

            if (buyallEmicount > 0) {
                var buyallBpInstAmount = (parseFloat(buyallPrice) / buyallEmicount).toFixed(2);
                $(this).parents(".product-tile").find(".bp-price").addClass("d-flex");
                $(this).parents(".product-tile").find(".bp-price").addClass("flex-wrap");
                $(this).parents(".product-tile").find(".bp-price").removeClass("d-none");
                $(this).parents(".product-tile").find(".item-installments-number").html(buyallEmicount);
                $(this).parents(".product-tile").find(".item-installments-amount").html(settings.currencySymbol+buyallBpInstAmount+" PAYMENTS*");
            }
        }
        return;
    }
    var isAvailable = $currentSelector.children("option:selected").data("isavailable");
    var itemName = $currentSelector.children("option:selected").data("itemname");
    var itemPrice = $currentSelector.children("option:selected").data("itemprice");
    var itemEmicount = $currentSelector.children("option:selected").data("itememicount");
    var bpInstAmount = itemEmicount > 0 ? (parseFloat(itemPrice) / itemEmicount).toFixed(2) : 0;
    var itemImageUrl = $currentSelector.children("option:selected").data("itemimageurl");
    var itemsecondimageurl = $currentSelector.children("option:selected").data("itemsecondimageurl");
    if (isAvailable) {
        $(this).parents(".product-tile").find(".sold-out-badge").hide();
        $(this).parents(".product-tile").find(".cart-wrapper").removeClass("d-none");
        $(this).parents(".product-tile").find(".fastbuy-wrapper").removeClass("d-none");
        $(this).parents(".product-tile").find(".notify-me-btn").addClass("d-none");
        if (auctionEndTime > 0) {
            $(this).parents(".shop-last-tile").find(".auction-timer-section").removeClass("d-none");
        }
    } else {
        $(this).parents(".product-tile").find(".sold-out-badge").show();
        $(this).parents(".product-tile").find(".cart-wrapper").addClass("d-none");
        $(this).parents(".product-tile").find(".fastbuy-wrapper").addClass("d-none");
        $(this).parents(".product-tile").find(".notify-me-btn").removeClass("d-none");
        $(this).parents(".shop-last-tile").find(".auction-timer-section").addClass("d-none");
    }
    //URL change for product link
    var productURLUpdated = $(this).parents(".product-tile").find(".product-name-link").attr("href");

    var endPoint = productURLUpdated.split("?")[0];
    endPoint = endPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".product-name-link").attr("href", endPoint);

    //URL change for wishlist icon
    var wishlistURLUpdated = $(this).parents(".product-tile").find(".wishlistTile").attr("data-action");
    var wishlistEndPoint = wishlistURLUpdated.split("?")[0];
    wishlistEndPoint = wishlistEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".wishlistTile").attr("data-action", wishlistEndPoint);

    //URL change for remove wishlist icon
    var remWishlistURLUpdated = $(this).parents(".product-tile").find(".remove-wishlist-btn").attr("data-url");
    var remWishlistEndPoint = remWishlistURLUpdated.split("?")[0];
    remWishlistEndPoint = remWishlistEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".remove-wishlist-btn").attr("data-url", remWishlistEndPoint);

    //URL change for product image
    var productImageURLUpdated = $(this).parents(".product-tile").find(".product-image-url").attr("href");
    var productImageEndPoint = productImageURLUpdated.split("?")[0];
    productImageEndPoint = productImageEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".product-image-url").attr("href", productImageEndPoint);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("src", itemImageUrl);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("data-secondary-image-src", itemsecondimageurl);
    $(this).parents(".product-tile").find(".product-image-url").find(".tile-image").attr("data-image-src", itemImageUrl);

    //Product Title change
    $(this).parents(".product-tile").find(".product-name-link").html(itemName);

    //Product Price change
    $(this).parents(".product-tile").find(".shoplc-price-value").html(settings.currencySymbol+itemPrice);

    //Product BP Details change
    if (itemEmicount > 0) {
        $(this).parents(".product-tile").find(".bp-price").addClass("d-flex");
        $(this).parents(".product-tile").find(".bp-price").addClass("flex-wrap");
        $(this).parents(".product-tile").find(".bp-price").removeClass("d-none");
        $(this).parents(".product-tile").find(".item-installments-number").html(itemEmicount);
        $(this).parents(".product-tile").find(".item-installments-amount").html(settings.currencySymbol+bpInstAmount+" PAYMENTS*");
    } else {
        $(this).parents(".product-tile").find(".bp-price").addClass("d-none");
        $(this).parents(".product-tile").find(".bp-price").removeClass("d-flex");
        $(this).parents(".product-tile").find(".bp-price").removeClass("flex-wrap");
    }

    //Product SKU change
    $(this).parents(".product-tile").find(".product-id").html(varientID);

    //URL change for quick view button
    var quickViewURLUpdated = $(this).parents(".product-tile").find(".quickview").attr("data-url");
    var quickViewEndPoint = quickViewURLUpdated.split("?")[0];
    quickViewEndPoint = quickViewEndPoint + "?pid=" + varientID;
    $(this).parents(".product-tile").find(".quickview").attr("data-url", quickViewEndPoint);

    //URL change for buy now and fast buy buttons
    $(this).parents(".shop-last-tile").attr("data-product-id", varientID);
    $(this).parents(".shop-last-tile").attr("data-pid", varientID);

    if (!isAvailable) {
        var notifyMeCtrl = $(this).parents(".product-tile").find(".livetv-submit-notifyme");
        var peObjJSONString = $(notifyMeCtrl).data("productdetails");
        var link = window.location.origin + "/" + varientID + ".html";
        var productDetObj;
        if ((typeof peObjJSONString === "string" || peObjJSONString instanceof String) && peObjJSONString != "") {
            productDetObj = JSON.parse(peObjJSONString);
        } else {
            productDetObj = peObjJSONString;
        }
        productDetObj.pid = varientID;
        productDetObj.pname = itemName;
        productDetObj.actualprice = itemPrice ? itemPrice.toString() : productDetObj.actualprice;
        productDetObj.link = link;
        productDetObj.bigimageurl = itemImageUrl;
        peObjJSONString = JSON.stringify(productDetObj);
        $(notifyMeCtrl).attr("data-productdetails", peObjJSONString);
        $(notifyMeCtrl).data("productdetails", peObjJSONString);
        $(notifyMeCtrl).attr("data-pid", varientID);
        $(notifyMeCtrl).data("data", varientID);
    }
});

function staticImageRender() {
    imageSwap.imageSwap();
    $(".tile-image").on("error", function () {
        var staticImageLink = $("#staticImgRender").val();
        $(this).attr("src", staticImageLink);
    });
}

$(window).on("scroll", function () {
    var bannerHeight = $(".top-banner-wrapper").height();
    var yScroll = $(window).scrollTop();
    if (yScroll > bannerHeight) {
        $("body").addClass("sticky-top").css("padding-top", bannerHeight);
    }
    else {
        $("body").removeClass("sticky-top");
    }
});

function displayLiveTvShopLast25ItemsNew() {
    var settings = $("#shopLastSettings").data();

    if (typeof settings === "undefined") {
        return;
    }

    $(window).on("scroll", function () {
        var scrollDistance = $(window).scrollTop() + $(window).height();
        var footerOffset = $(".footer-block").offset();
        var footerDistance = footerOffset !== undefined ? $(".footer-block").offset().top : 0;
        var stopAPICall = $("#liveTvShopLast25").hasClass("js-hit-api");
        if (scrollDistance >= footerDistance) {
            if (stopAPICall) {
                $("#liveTvShopLast25").removeClass("js-hit-api");
                offset = offset + 25;
                $.ajax({
                    url: settings.shopLast25ItemsControllerUrl + "?offSet=" + offset + "&limit=" + limit,
                    dataType: "json",
                    method: "GET",
                    success: function (data) {
                        //$form.spinner().stop();
                        if (!data.success) {
                            console.log("success false");
                        } else {
                            var shopLast25Itemsdata = data.shopLast25Items;
                            shopLast25Itemsdata.forEach(function (shopLast25Item) {
                                renderLiveTvShopLast25ItemsNew(shopLast25Item.stockListDetail);
                            });
                        }
                    },
                    error: function (err) {
                        console.log(err);
                    }
                });
            }
        }

    });

}

function renderLiveTvShopLast25ItemsNew(shopLast24hItems) {
    var settings = $("#shopLastSettings").data();
    if (typeof settings === "undefined") {
        return;
    }

    var $liveTvShopLast24Grid = $("#liveTvShopLast25ItemGrid");
    //var $tilesGridWrap = $("<div/>", { class: "product-grid shop-last25items shop-last-grid d-flex flex-wrap" });
    var position = 1;
    shopLast24hItems.forEach(function (item) {
        var productId = item.stockCode;
        var path = [productId.substring(0, 2), productId.substring(2, 3), productId].join("/");
        path = [productId.substring(0, 2), productId.substring(2, 3), productId, productId].join("/");
        var baseProdURL = settings.sirvImgUrl.replace("{path}", path);
        var baseProdURLObj = new URL(baseProdURL);
        baseProdURLObj.searchParams.append("w", 300);
        baseProdURLObj.searchParams.append("h", 300);
        var productImageUrl = baseProdURLObj.href.toString();
        var productSecondImageUrl = productImageUrl.split(".jpg");
        if (productSecondImageUrl[0].indexOf("img_no_thumbnail") > -1) {
            productSecondImageUrl = productImageUrl;
        } else {
            productSecondImageUrl[0] = productSecondImageUrl[0]+"_1";
            productSecondImageUrl = productSecondImageUrl.join(".jpg");
        }

        // const productSecondImageUrl = settings.thumbSecondImageUrl.replace("{path}", path) .replace("{position}", 1 ).replace("{position}", 1 );

        var currentPrice = item.price;
        var estRetValueVal = parseFloat(currentPrice * 4).toFixed(2); //item.startprice;
        var estSavingsVal = (100 * parseFloat(1 - currentPrice / estRetValueVal)).toFixed(2);

        var itemObj = {
            productid: productId,
            productname: item.name,
            productImageUrl: productImageUrl,
            productSecondImageUrl: productSecondImageUrl,
            currentprice: currentPrice,
            estRetValueVal: estRetValueVal,
            estSavingsVal: estSavingsVal,
            emicount: item.emicount,
            itemType: "24",
            select1: item.singleVariants,
            select2: item.multiVariants,
            sizes : item.multiVariants,
            SkuProductId: item.groupNames,
            isMLA: item.isMLA,
            isGroupAuction: item.isGroupAuction,
            isBuyAll: item.isBuyAll,
            buyAllDiscount: item.buyAllDiscount,
            buyAllPrice: item.buyAllPrice,
            shippingCharges: item.shippingCharges,
            actualAuctionEndTime: item.actualAuctionEndTime,
            isAvailable: item.isAvailable
        };

        //$tilesGridWrap.append(renderShopLastItemNew(itemObj, settings));
        $liveTvShopLast24Grid.append(renderShopLastItem(itemObj, settings));
        $("#liveTvShopLast25").addClass("js-hit-api");
        $(".show-more-button").removeClass("d-none");

        var last25Item = {
            id: productId,
            name: item.productname,
            price: currentPrice,
            list: "LIVE TV - Last 25 Items",
            position: "" + position
        };
        last25Items.push(last25Item);
        position = position + 1;
    });

    startCountdown();
    shopLastEvents();
    staticImageRender();
    setTimeout(function () {
        if (last25Items.length > 0) {
            var noOfImpressions = 0;
            last25Items.forEach(function (LTLastItem) {
                gaHelper.fireGAEvent("AddListItem", LTLastItem);
                noOfImpressions++;
                if (noOfImpressions == 20) {
                    noOfImpressions = 0;
                    gaHelper.fireGAEvent("FireListItem");
                }
            });
            if (noOfImpressions % 20 != 0) {
                gaHelper.fireGAEvent("FireListItem");
            }
            last25Items = [];
        }
    }, 5000);
}

function getShopLast24Hours() {
    setTimeout(function () {
        var $wrapper = $(".shoplast24h-carousel-wrapper");

        var url = $("#shopLastSettings").data("shop-last-24-hours-url");

        $.ajax({
            url: url,
            method: "GET",
            dataType: "json",
            success: function (response) {
                var templates = response.templates;

                $wrapper.addClass("shoplast24h-carousel owl-carousel");

                templates.forEach(function (template) {
                    $wrapper.append(template);
                });

                shopLast24HoursEvents();

            },
            error: function () {
            }
        });
    }, 2000);
}

module.exports = {
    renderLiveTvShopLast25Items: renderLiveTvShopLast25Items,
    shopLast24HoursEvents: shopLast24HoursEvents,
    renderLiveTvProductDetails: renderLiveTvProductDetails,
    renderLiveTvProductDetailsEvent: renderLiveTvProductDetailsEvent,
    handleAddToCart: handleAddToCart,
    handleProductVariationValueSelections: handleProductVariationValueSelections,
    handleQuantityChange: handleQuantityChange,
    isVariationSelectionOk: isVariationSelectionOk,
    renderHomepageLiveTvShopLast25Items: renderHomepageLiveTvShopLast25Items,
    displayRecentlyOnAirItems: displayRecentlyOnAirItems,
    getShopLast24Hours: getShopLast24Hours
};
