var gaLogs = require("./trackingGAlogs");
/**
 * Fires GA event based on the event name
 * @param {string} eventName - event name
 * @param {Object} data - event data in JSON
 */
function fireGAEvent(eventName, formData, source, flag) {
    var gaDataLayerObject;
    if (resources.prefs.enableGA == "true" && typeof(ga) != "undefined") {
        try {
            switch (eventName) {
                case "HOME":
                    var imgIndex = 0;
                    var gaID = "Homepage-Desktop";
                    var isMobile = /Android|Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Silk|Opera Mini/i.test(navigator.userAgent) ? true : false;
                    if (isMobile) {
                        gaID = "Homepage-Mobile";
                    }
                    var fireHomeevent = false;
                    $(".main-hero-carousel").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category = "Hero";
                            var position = imgIndex + 1;
                            imgIndex++;
                            position = position.toString();
                            var gaObj = {
                                id: gaID,
                                name: category,
                                creative: this.alt || "",
                                position: position
                            };
                            ga("ec:addPromo", gaObj);// eslint-disable-line
                            $(this).click(function () {
                                ga("ec:addPromo", gaObj); // eslint-disable-line
                                ga("ec:setAction", "promo_click"); // eslint-disable-line
                                ga("send", "event", "Internal Promotions", "click", category); // eslint-disable-line
                            });
                            fireHomeevent = true;
                        }
                    });
                    imgIndex = 0;
                    $(".mobile-2r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category1 = "Para1";
                            var position1 = imgIndex + 1;
                            imgIndex++;
                            position1 = position1.toString();
                            var gaObj1 = {
                                id: gaID,
                                name: category1,
                                creative: this.alt || "",
                                position: position1
                            };
                            ga("ec:addPromo", gaObj1);// eslint-disable-line
                            $(this).click(function () {
                                ga("ec:addPromo", gaObj1); // eslint-disable-line
                                ga("ec:setAction", "promo_click"); // eslint-disable-line
                                ga("send", "event", "Internal Promotions", "click", category1); // eslint-disable-line
                            });
                            fireHomeevent = true;
                        }
                    });
                    imgIndex = 0;
                    $(".mobile-3r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category2 = "Para2";
                            var position2 = imgIndex + 1;
                            imgIndex++;
                            position2 = position2.toString();
                            var gaObj2 = {
                                id: gaID,
                                name: category2,
                                creative: this.alt || "",
                                position: position2
                            };
                            ga("ec:addPromo", gaObj2);// eslint-disable-line
                            $(this).click(function () {
                                ga("ec:addPromo", gaObj2); // eslint-disable-line
                                ga("ec:setAction", "promo_click"); // eslint-disable-line
                                ga("send", "event", "Internal Promotions", "click", category2); // eslint-disable-line
                            });
                            fireHomeevent = true;
                        }
                    });
                    imgIndex = 0;
                    $(".simple-1r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category3 = "Para3";
                            var position3 = imgIndex + 1;
                            imgIndex++;
                            position3 = position3.toString();
                            var gaObj3 = {
                                id: gaID,
                                name: category3,
                                creative: this.alt || "",
                                position: position3
                            };
                            ga("ec:addPromo", gaObj3);// eslint-disable-line
                            $(this).click(function () {
                                ga("ec:addPromo", gaObj3); // eslint-disable-line
                                ga("ec:setAction", "promo_click"); // eslint-disable-line
                                ga("send", "event", "Internal Promotions", "click", category3); // eslint-disable-line
                            });
                            fireHomeevent = true;
                        }
                    });
                    imgIndex = 0;
                    $(".experience-commerce_layout-bannerGroup .bannergroup-wrap").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category4 = "Para4";
                            var position4 = imgIndex + 1;
                            imgIndex++;
                            position4 = position4.toString();
                            var gaObj4 = {
                                id: gaID,
                                name: category4,
                                creative: this.alt || "",
                                position: position4
                            };
                            ga("ec:addPromo", gaObj4);// eslint-disable-line
                            $(this).click(function () {
                                ga("ec:addPromo", gaObj4); // eslint-disable-line
                                ga("ec:setAction", "promo_click"); // eslint-disable-line
                                ga("send", "event", "Internal Promotions", "click", category4); // eslint-disable-line
                            });
                            fireHomeevent = true;
                        }
                    });
                    if (fireHomeevent) {
                        ga("send", "event", "Home", "Promotion-banners", {nonInteraction: 1}) // eslint-disable-line
                    }
                    break;
                case "PDP":
                    if ($(document).find("#gaDataLayerObject").length > 0) {
                        gaDataLayerObject = $(document).find("#gaDataLayerObject").data("gadata");
                        if (formData.pagetype && formData.pagetype == "Homepage") {
                            gaDataLayerObject.itemData.list = "LIVE TV";
                            ga("ec:addImpression", gaDataLayerObject.itemData); //eslint-disable-line
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1});// eslint-disable-line
                        } else {
                            var productPrice = ""; 
                            if ($(".main-info-container-left .price-container .sales .js-formatted-price").length > 0) {
                                productPrice = $(".main-info-container-left .price-container .sales .js-formatted-price").html().trim().replace("$", "").replace("NOW:", "").trim();
                            } else if ($("#liveTvProductCurrentPrice").length > 0) {
                                productPrice = $("#liveTvProductCurrentPrice").html().trim().replace("$", "").replace("NOW:", "").trim();
                            }
                            gaDataLayerObject.itemData.price = productPrice;
                            ga("ec:addProduct", gaDataLayerObject.itemData); // eslint-disable-line
                            ga("ec:setAction", "detail", {list: gaDataLayerObject.pdpType}); // eslint-disable-line
                            ga("send", "event", "Product", "Detail-View", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    break;
                case "LTATC":
                    if (formData) {
                        ga("ec:addProduct", formData); // eslint-disable-line
                        ga("ec:setAction", "add", {list: source}); // eslint-disable-line
                        ga("send", "event", "UX", "click", "add to cart"); // eslint-disable-line
                    }
                    break;
                case "LTLastItem":
                    if (formData) {
                        formData.forEach(function (LTLastItem) {
                            ga("ec:addImpression", LTLastItem);// eslint-disable-line
                        });
                        ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1});// eslint-disable-line
                    }
                    break;
                case "AddListItem":
                    if (formData) {
                        ga("ec:addImpression", formData);// eslint-disable-line
                    }
                    break;
                case "FireListItem":
                    ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1});// eslint-disable-line
                    break;
                case "ADD_TO_CART":
                    if (formData && $(document).find("#gaATCDataLayerObject").length > 0) {
                        gaDataLayerObject = $(document).find("#gaATCDataLayerObject").data("gadata");
                        gaDataLayerObject.quantity = formData.quantity;
                        gaDataLayerObject.price = formData.gaprice;
                        ga("ec:addProduct", gaDataLayerObject); // eslint-disable-line
                        ga("ec:setAction", "add", {list: source}); // eslint-disable-line
                        ga("send", "event", "UX", "click", "add to cart"); // eslint-disable-line
                    }
                    break;
                case "PLP_ADD_TO_CART":
                    if (formData) {
                        ga("ec:addProduct", formData); // eslint-disable-line
                        ga("ec:setAction", "add", {list: source}); // eslint-disable-line
                        ga("send", "event", "UX", "click", "add to cart"); // eslint-disable-line
                    }
                    break;
                case "UPDATE_QTY_CART":
                    if (formData) {
                        ga("ec:addProduct", formData); // eslint-disable-line
                        ga("ec:setAction", "update"); // eslint-disable-line
                        ga("send", "event", "UX", "click", "quantity update"); // eslint-disable-line
                    }
                    break;
                case "REMOVE_FROM_CART":
                    if (formData) {
                        ga("ec:addProduct", formData); // eslint-disable-line
                        ga("ec:setAction", "remove"); // eslint-disable-line
                        ga("send", "event", "UX", "click", "remove to cart"); // eslint-disable-line
                    }
                    break;
                case "CHECKOUT":
                    if ($(document).find("#gaDataLayerObject").length > 0) {
                        gaDataLayerObject = $(document).find("#gaDataLayerObject").data("gadata");
                        if (gaDataLayerObject.products && gaDataLayerObject.products.length > 0) {
                            var coption = gaDataLayerObject.option;
                            if ($(".payment-options-loggedin").length > 0) {
                                $("input[type='radio'][name='payment-method']").each(function () {
                                    if ($(this).is(":checked")) {
                                        coption = $(this).attr("data-payment-id");
                                    }
                                });
                            } else if ($("ul.payment-options .js-nav-item").length > 0) {
                                $("ul.payment-options .js-nav-item").each(function () {
                                    if ($(this).find("a.nav-link").hasClass("active")) {
                                        coption = $(this).attr("data-method-id");
                                    }
                                });
                            }
                            if (gaDataLayerObject.checkoutStep == "2" && coption == "payment") {
                                break;
                            }
                            gaDataLayerObject.products.forEach(function (product) {
                                ga("ec:addProduct", product);// eslint-disable-line
                            });
                            ga("ec:setAction","checkout", {"step": gaDataLayerObject.checkoutStep, "option": coption}); // eslint-disable-line
                            ga("send", "event", "Checkout", "Checkout-View", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    setTimeout(function () {
                        if ($("#You-May-Also-Like .recently-on-air-items-col").length > 0) {
                            var recommpos6 = 1;
                            var noOfImpressions6 = 0;
                            $("#You-May-Also-Like .recently-on-air-items-col .dy-product-tile").each(function () {
                                var productName6 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                                ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productName6, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos6});// eslint-disable-line
                                recommpos6++;
                                noOfImpressions6++;
                                if (noOfImpressions6 == 20) {
                                    noOfImpressions6 = 0;
                                    ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                                }
                            }); 
                            if (noOfImpressions6 % 20 != 0) {
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }
                        if ($("#based-browsing-history .recently-on-air-items-col").length > 0) {
                            var recommpos7 = 1;
                            var noOfImpressions7 = 0;
                            $("#based-browsing-history .recently-on-air-items-col .dy-product-tile").each(function () {
                                var productName7 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                                ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productName7, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos7});// eslint-disable-line
                                recommpos7++;
                                noOfImpressions7++;
                                if (noOfImpressions7 == 20) {
                                    noOfImpressions7 = 0;
                                    ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                                }
                            }); 
                            if (noOfImpressions7 % 20 != 0) {
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }
                        if ($("#Customers-Also-Bought .recently-on-air-items-col").length > 0) {
                            var recommpos8 = 1;
                            var noOfImpressions8 = 0;
                            $("#Customers-Also-Bought .recently-on-air-items-col .dy-product-tile").each(function () {
                                var productName8 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                                ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productName8, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos8});// eslint-disable-line
                                recommpos8++;
                                noOfImpressions8++;
                                if (noOfImpressions8 == 20) {
                                    noOfImpressions8 = 0;
                                    ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                                }
                            }); 
                            if (noOfImpressions8 % 20 != 0) {
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }
                    }, 15000);
                    break;
                case "SHM_UPDATE":
                    if (formData) {
                        ga("ec:setAction","checkout", {"step": 1,"option": formData.id}); // eslint-disable-line
                        ga("send", "event", "Checkout", "Option", {"nonInteraction": 1}); // eslint-disable-line
                    }
                    break;
                case "PURCHASE":
                    if ($(document).find("#gaOrderDataLayerObject").length > 0) {
                        gaDataLayerObject = $(document).find("#gaOrderDataLayerObject").data("gadata");
                        if (gaDataLayerObject) {
                            if (gaDataLayerObject.productsData && gaDataLayerObject.productsData.length > 0) {
                                gaDataLayerObject.productsData.forEach(function (product) {
                                    ga("ec:addProduct", product);// eslint-disable-line
                                });
                            }
                            ga("ec:setAction", "purchase", gaDataLayerObject.purchaseData); // eslint-disable-line
                            ga("send", "event","Checkout", gaDataLayerObject.purchaseType, {"nonInteraction": 1}); // eslint-disable-line
                            var successMsg = "Success";
                            gaLogs.logGAEvent(gaDataLayerObject.purchaseData.id, successMsg);

                        } else {
                            var noDom = "error";
                            var noData = "";
                            gaLogs.logGAEvent(noData, noDom);
                        } 
                    } else {
                        var errorObj = "No order data available";
                        var noObj = "";
                        gaLogs.logGAEvent(noObj, errorObj);
                    }
                    break;
                case "BID":
                    if (formData) {
                        ga("ec:addProduct", formData); // eslint-disable-line
                        ga("ec:setAction", "add", {list: source}); // eslint-disable-line
                        ga("send", "event", "UX", "click", flag ? "Max BID" : "BID NOW"); // eslint-disable-line
                    }
                    break;
                case "PLP":
                    var sendImpression = false;
                    var plpNoOfImpressions = 0;
                    var listType = $("#risingAuctionPLPSection").length > 0 ? "RA PLP" : "PLP";
                    $(".product-tile-wrapper").each(function () {
                        if ($(this).find(".product").attr("data-ga-send") != "true") {
                            var productNametile = $(this).find(".product").attr("data-pname").length > 0 ? $(this).find(".product").attr("data-pname").replace(/"/g, "") : $(this).find(".product").attr("data-pname");
                            var tileData = {
                                id: $(this).find(".product").attr("data-pid"),
                                name: productNametile,
                                category: $(this).find(".product").attr("data-pcategory"),
                                list: listType
                            };
                            if (listType == "PLP") {
                                tileData.position = $(this).find("#gaTileDataObject").attr("data-position");
                            } else {
                                tileData.position = plpNoOfImpressions + 1;
                            }
                            ga("ec:addImpression", tileData); // eslint-disable-line
                            $(this).find(".product").attr("data-ga-send", "true");
                            sendImpression = true;
                            plpNoOfImpressions++;
                            if (plpNoOfImpressions == 20) {
                                plpNoOfImpressions = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }
                    });
                    if (sendImpression && plpNoOfImpressions % 20 != 0) {
                        ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                    }
                    break;
                case "NEW_USER":
                    if (formData) {
                        if (typeof formData.customerId !== "undefined" && formData.customerId != "anonymous") {
                            ga("ec:newCustomer", formData); // eslint-disable-line
                            ga("ec:setAction", "registration", {list: "Registration"}); // eslint-disable-line
                            ga("send", "event", "Customer", "NewUserRegistartion", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    break;
                case "PAGE_VIEW":
                    ga('set', 'page', formData.pageURL); //eslint-disable-line
                    ga("send", "pageview", {"dimension8": "SFCC"}); // eslint-disable-line
                    break;
                case "PDP_RECOMM":
                    if ($("#customer-also-Viewed .dy-product-tile").length > 0) {
                        var custAlsoViewed = 1;
                        var pdpNoOfImpressions = 0;
                        $("#customer-also-Viewed .dy-product-tile").each(function () {
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: $(this).find(".thumbnail-wrapper a").attr("pname"), category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + custAlsoViewed}); //eslint-disable-line 
                            custAlsoViewed++;
                            pdpNoOfImpressions++;
                            if (pdpNoOfImpressions == 20) {
                                pdpNoOfImpressions = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        });
                        if (pdpNoOfImpressions % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#customer-also-bought .dy-product-tile").length > 0) {
                        var custAlsoBought = 1;
                        var pdpNoOfImpressions1 = 0;
                        $("#customer-also-bought .dy-product-tile").each(function () {
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: $(this).find(".thumbnail-wrapper a").attr("pname"), category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + custAlsoBought}); //eslint-disable-line 
                            custAlsoBought++;
                            pdpNoOfImpressions1++;
                            if (pdpNoOfImpressions1 == 20) {
                                pdpNoOfImpressions1 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        });
                        if (pdpNoOfImpressions1 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    break;
                case "HOME_RECOMM":
                    if ($("#recommended-for-you .recently-on-air-items-col").length > 0) {
                        var recommpos = 1;
                        var noOfImpressions = 0;
                        $("#recommended-for-you .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec101 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec101, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos});// eslint-disable-line
                            recommpos++;
                            noOfImpressions++;
                            if (noOfImpressions == 20) {
                                noOfImpressions = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressions % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#Hp_Custom_recs .recently-on-air-items-col").length > 0) {
                        var recommpos1 = 1;
                        var noOfImpressions1 = 0;
                        $("#Hp_Custom_recs .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec102 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec102, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos1});// eslint-disable-line
                            recommpos1++;
                            noOfImpressions1++;
                            if (noOfImpressions1 == 20) {
                                noOfImpressions1 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        });
                        if (noOfImpressions1 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#recently-viewed-HP .recently-on-air-items-col").length > 0) {
                        var recommpos2 = 1;
                        var noOfImpressions2 = 0;
                        $("#recently-viewed-HP .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec103 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec103, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos2});// eslint-disable-line
                            recommpos2++;
                            noOfImpressions2++;
                            if (noOfImpressions2 == 20) {
                                noOfImpressions2 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        });
                        if (noOfImpressions2 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#Trending-now .recently-on-air-items-col").length > 0) {
                        var recommpos3 = 1;
                        var noOfImpressions3 = 0;
                        $("#Trending-now .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec104 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec104, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos3});// eslint-disable-line
                            recommpos3++;
                            noOfImpressions3++;
                            if (noOfImpressions3 == 20) {
                                noOfImpressions3 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressions3 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#Personalize-jewelry .recently-on-air-items-col").length > 0) {
                        var recommpos4 = 1;
                        var noOfImpressions4 = 0;
                        $("#Personalize-jewelry .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec105 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec105, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos4});// eslint-disable-line
                            recommpos4++;
                            noOfImpressions4++;
                            if (noOfImpressions4 == 20) {
                                noOfImpressions4 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressions4 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#hp-Loose-Gemstone .recently-on-air-items-col").length > 0) {
                        var recommpos5 = 1;
                        var noOfImpressions5 = 0;
                        $("#hp-Loose-Gemstone .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRec106 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRec106, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommpos5});// eslint-disable-line
                            recommpos5++;
                            noOfImpressions5++;
                            if (noOfImpressions5 == 20) {
                                noOfImpressions5 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressions5 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#web-ex-HP .recently-on-air-items-col").length > 0) {
                        var recommposH6 = 1;
                        var noOfImpressionsH6 = 0;
                        $("#web-ex-HP .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRecH6 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRecH6, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommposH6});// eslint-disable-line
                            recommposH6++;
                            noOfImpressionsH6++;
                            if (noOfImpressionsH6 == 20) {
                                noOfImpressionsH6 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressionsH6 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#Best-Seller .like-recently-viewed").length > 0) {
                        var recommposH7 = 1;
                        var noOfImpressionsH7 = 0;
                        $("#Best-Seller .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRecH7 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRecH7, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommposH7});// eslint-disable-line
                            recommposH7++;
                            noOfImpressionsH7++;
                            if (noOfImpressionsH7 == 20) {
                                noOfImpressionsH7 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressionsH7 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    if ($("#Best-deals .like-recently-viewed").length > 0) {
                        var recommposH8 = 1;
                        var noOfImpressionsH8 = 0;
                        $("#Best-deals .recently-on-air-items-col .dy-product-tile").each(function () {
                            var productNameRecH8 = $(this).find(".thumbnail-wrapper a").attr("pname").length > 0 ? $(this).find(".thumbnail-wrapper a").attr("pname").replace(/"/g, "") : $(this).find(".thumbnail-wrapper a").attr("pname");
                            ga("ec:addImpression", {id: $(this).find(".thumbnail-wrapper a").attr("psku"), name: productNameRecH8, category: $(this).find(".thumbnail-wrapper a").attr("pcategory"), list: $(this).find(".thumbnail-wrapper a").attr("sourcebuy"), position: "" + recommposH8});// eslint-disable-line
                            recommposH8++;
                            noOfImpressionsH8++;
                            if (noOfImpressionsH8 == 20) {
                                noOfImpressionsH8 = 0;
                                ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                            }
                        }); 
                        if (noOfImpressionsH8 % 20 != 0) {
                            ga("send", "event", "Product", "addImpression", "Product Impression", {"nonInteraction": 1}); // eslint-disable-line
                        }
                    }
                    break;
                default :
                    ga("send", "pageview", {"dimension8": "SFCC"}); // eslint-disable-line
            }
        } catch (e) {
            return;
        }
    }
}

module.exports = {
    fireGAEvent: fireGAEvent
};
