var ytPlayer;
var hasBeenPaused = false;
var playerLoaded = false;

function onPlayerReady() {
    ytPlayer.mute();
    ytPlayer.playVideo();
}

function onPlayerStateChange() {
    // pause state
    if (ytPlayer.getPlayerState() === 2) {
        hasBeenPaused = true;
    }

    // buffering state
    if (ytPlayer.getPlayerState() === 3) {
        if (hasBeenPaused) {
            hasBeenPaused = false;
            ytPlayer.stopVideo();
            ytPlayer.playVideo();
        }
    }
}

function loadJS(url, location) {
    var scriptTag = document.createElement("script");
    scriptTag.setAttribute("defer", "");
    scriptTag.src = url;
    location.appendChild(scriptTag);
}

function loadPlayer() {
    if (!playerLoaded) {
        playerLoaded = true;

        var $veygoPlayerWrapper = $("#veygoVideoFrame");
        var $ytPlayerWrapper = $("#ytPlayerContainer");
        var pageType = $(".js-main-player-wrapper").data("page-type");

        // Load the JavaScript file when the div is in view
        if ($veygoPlayerWrapper.length && (pageType === "HOMEPAGE_NEW" || pageType === "HOMEPAGE2_NEW")) {
            var scripts = $(".veygo-player-scripts");

            if (scripts.length) {
                loadJS(scripts.data("vipfull-script-url"), document.body);
            }
        }

        if ($ytPlayerWrapper.length) {
            if (pageType === "HOMEPAGE_NEW" || pageType === "HOMEPAGE2_NEW") {
                $ytPlayerWrapper.html("<iframe id=\"ytPlayerIframe\" allowfullscreen class=\"embed-responsive-item\" src=\"" + $ytPlayerWrapper.data("yt-url") + "\"></iframe>");
            }

            loadJS("https://www.youtube.com/iframe_api", document.body);

            window.onYouTubeIframeAPIReady = function () {
                ytPlayer = new window.YT.Player("ytPlayerIframe", {
                    events: {
                        "onReady": onPlayerReady,
                        "onStateChange": onPlayerStateChange
                    }
                });
            };
        }
    }
}

function createIntersectionObserver(targetElement, callback) {
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
    };



    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                callback();
                observer.unobserve(entry.target);
            }
        });
    }, options);



    observer.observe(targetElement);
}

module.exports = function () {
    var loadingTarget = $(".js-main-player-wrapper").data("loading-target");
    var $veygoPlayerWrapper = $("#veygoVideoFrame");
    var $ytPlayerWrapper = $("#ytPlayerContainer");
    var $mainLoadingTarget = $(loadingTarget);
    var targetPlayerDiv = $veygoPlayerWrapper.length ? $veygoPlayerWrapper.get(0) : $ytPlayerWrapper.get(0);
    var targetSectionDiv = $mainLoadingTarget.get(0);

    if (targetPlayerDiv) {
        createIntersectionObserver(targetPlayerDiv, loadPlayer);
    }

    if (targetSectionDiv) {
        createIntersectionObserver(targetSectionDiv, loadPlayer);
    }
};
