module.exports = {
    
    generateMaxItemCartModal: function (message, cartUrl) {
        var $messageModal = $("#maxBidModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }
        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal auction-alert-modal fade\" id=\"maxBidModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog modal-dialog-centered\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-close-btn\" data-dismiss=\"modal\">"+
                        "<span class=\"cross-icon\"></span>" +
                    "</div>"+
                    "<div class=\"modal-info-block\">" + 
                        "<i class=\"fa fa-exclamation-triangle\"></i>" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<button class=\"btn btn-cancel\" data-dismiss=\"modal\">Cancel</button>" +
                        "<a class=\"btn btn-paynow pay-now-cta\" href=\"" + cartUrl + "\">Continue To Cart</a>" +                    
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#maxBidModal"};
    }
};
