/**
 * Added mouse over event to the image.
 */
function imageSwap() {
    $(".productTileImage").on("mouseenter", function () {
        var src = $(this).attr("data-secondary-image-src");
        if (src != null && src != "null") {
            $(this).attr("src", src);
        }
    });

    $(".productTileImage").on("mouseleave", function () {
        var src = $(this).attr("data-image-src");
        $(this).attr("src", src);
    });

    $(".productTileImage").on("touchend", function () {
        var src = $(this).attr("data-secondary-image-src");
        if (src != null && src != "null") {
            $(this).attr("src", src);
        }
    });
}

module.exports = {
    imageSwap: imageSwap
};
