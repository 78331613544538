module.exports = {
    init: function () {
        var $carousel = $(".product-carousel");
        if ($carousel.length > 0) {
            $carousel.owlCarousel({
                margin:30,
                loop: true,
                nav: true,
                smartSpeed: 900,
                navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
                responsive: {
                    0: {
                        nav: false,
                        items: 1.5
                    },
                    544: {
                        nav: false,
                        items: 3.5
                    },
                    769: {
                        nav: true,
                        items: 5.5
                    }
                }
            });
        }

        var $onAirCarousel = $(".onair-carousel");
        if ($onAirCarousel.length > 0) {
            $onAirCarousel.owlCarousel({
                loop: false,
                dots: false,
                nav: true,
                smartSpeed: 900,
                margin: 10,
                responsiveClass:true,
                navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
                responsive: {
                    0: {
                        nav: true,
                        items: 2
                    },
                    544: {
                        nav: true,
                        items: 3
                    },
                    992: {
                        nav: true,
                        items: 5
                    },
                    1024: {
                        nav: true,
                        items: 6
                    }
                }
            });
        }
    },
};
