var processInclude = require("base/util");
var gaHelper = require("./tracking/googleanalytics");
 
$(document).ready(function () {
    var loaded = false;
    processInclude(require("./product/selectSwatchable"));
    processInclude(require("./product/quickViewModal"));
    processInclude(require("./product/quickView"));
    processInclude(require("./product/addToCart"));
    var tvSelector = $("#liveTvVideoSection").attr("data");
    $("#shoplcVideo").attr("autoplay", "");
    document.addEventListener("scroll", function () {
        var isReached = isElementInView(tvSelector, loaded);
        if (loaded == false & isReached) {
            processInclude(require("./liveTv/liveTvProductsDetails"));
            loaded = true;
        }  
    });
    processInclude(require("./liveTv/liveTvPlayer"));
    processInclude(require("./liveTv/liveTvHelper"));
    gaHelper.fireGAEvent("HOME");
    setTimeout(function () {
        gaHelper.fireGAEvent("HOME_RECOMM");
    }, 15000);
});

function isElementInView(element, fullyInView) {
    var pageTop = $(window).scrollTop();
    var pageBottom = pageTop + $(window).height();
    var elementTop = $(element).offset().top;
    if (pageBottom >= elementTop) {
        fullyInView = true;
        return fullyInView;
    } else {
        fullyInView = false;
        return fullyInView;
    }
}
