/**
 * Fires Comment Sold event based on the event name
 * @param {string} eventName - event name
 * @param {Object} data - event data in JSON
 */
function pushEngageEvent(eventName, data=null) {
    var script = document.createElement("script");
    var dataObj = "";
    try {
        switch (eventName) {
            case "ADD_TO_CART_BA":
                dataObj = 'window._peq.push(["add-to-trigger",{"campaign_name": "New Triggered Campaign-VlEdtJ","event_name": "add-to-cart"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "BROWSE_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{'+
                    '"campaign_name": "New Triggered Campaign-VlEdtJ",'+
                    '"event_name": "browse",'+
                    '"title": {'+
                        '"productname": "'+data.productname+'"'+
                    '},'+
                    '"message": {'+
                        '"price": "'+data.price+'"'+
                    '},'+
                    '"notification_url": {'+
                        '"notificationurl": "'+data.notificationurl+'" '+
                    '},'+
                    '"notification_image": {'+
                        '"imageurl": "'+data.imageurl+'"'+
                    '},'+
                    '"big_image": {'+
                        '"bigimageurl": "'+data.bigimageurl+'"'+
                    '}'+
                '}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "GOAL_TRACKING":
                dataObj = 'var goal = {'+
                    'name: "revenue",'+
                    'count: '+data.productQuantityTotal+','+
                    'value: '+data.fullPayableAmount+','+
                    '};'+
                    '_peq.push(["goal", goal])';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "ADD_TO_CART_CA":
                dataObj = 'window._peq.push(["add-to-trigger",{'+
                    '"campaign_name": "New Triggered Campaign-5mJPAa",'+
                    '"event_name": "add-to-cart",'+
                    '"title": {'+
                        '"productname": "'+data.title+'"'+
                    '},'+
                    '"message": {'+
                        '"price": "'+data.alertprice+'"'+
                    '},'+
                    '"notification_url": {'+
                        '"notificationurl": "'+data.link+'"'+
                    '},'+
                    '"notification_image": {'+
                        '"imageurl": "'+data.imageurl+'"'+
                    '},'+
                    '"big_image": {'+
                        '"bigimageurl": "'+data.bigimageurl+'"'+
                    '}'+
                '}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "CHECKOUT_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{"campaign_name": "New Triggered Campaign-5mJPAa","event_name": "checkout", "data": {"revenue": ""}}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "BACK_IN_STOCK_ALERT":
                dataObj = 'window._peq.push(["add-alert",{'+
                    '"type": "inventory",'+
                    '"product_id": "'+data.productid+'",'+
                    '"link": "'+data.link+'",'+
                    '"price": "'+data.alertprice+'",'+
                    '"alert_price": "'+data.alertprice+'",'+
                    '"ts_expires": "'+data.expiryisodate+'",'+
                    '"availability": "'+data.availability+'",'+
                    '"variant_id": "'+data.variantid+'",'+
                    '"title": "'+data.title+'",'+
                    '"image_link": "'+data.imageurl+'",'+
                    '"big_image_link": "'+data.bigimageurl+'"'+
                '}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "PRICE_DROP_ALERT":
                dataObj = 'window._peq.push(["add-alert",{'+
                    '"type": "price_drop",'+
                    '"product_id": "'+data.productid+'",'+
                    '"link": "'+data.link+'",'+
                    '"price": "'+data.price+'",'+
                    '"alert_price": "'+data.alertprice+'",'+
                    '"ts_expires": "'+data.expiryisodate+'",'+
                    '"availability": "'+data.availability+'",'+
                    '"variant_id": "'+data.variantid+'",'+
                    '"title": "'+data.title+'",'+
                    '"image_link": "'+data.imageurl+'",'+
                    '"big_image_link": "'+data.bigimageurl+'"'+
                '}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "WEBTV_BROWSE_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{"campaign_name": "New Triggered Campaign-webtvdropoff","event_name": "livetv_browse_event"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "WEBTV_ACTIVITY_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{"campaign_name": "New Triggered Campaign-webtvdropoff","event_name": "livetv_activity_event"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "CATEGORY_BROWSE_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",'+JSON.stringify(data)+']);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "CATEGORY_ACTIVITY_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{"campaign_name": "New Triggered Campaign-categorydropoff","event_name": "category_activity_event"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "SAVE_SUBSCRIBER_HASH":
                dataObj = 'window._peq.push(['+
                    '"subscriber-status",'+
                    'function(res) {'+
                        'if (res.statuscode == 1) {'+
                            'console.log(res);'+
                            'var url = "'+ data + '" + "&subscriberhash=" + res.data.subscriber_hash;'+
                            '$.ajax({'+
                                'url: url,'+
                                'method: "GET",'+
                                'success: function (data) {'+
                                '},'+
                                'error: function (err) {'+
                                    'console.log(err);'+
                                '}'+
                            '});'+
                        '}'+
                    '},'+
                ']);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "STOP_EVENT_INACTIVE_30_DAYS":
                dataObj = 'window._peq.push(["add-to-trigger", { "campaign_name": "New Triggered Campaign-30daysinactive", "event_name": "active30days"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "STOP_EVENT_INACTIVE_90_DAYS":
                dataObj = 'window._peq.push(["add-to-trigger", { "campaign_name": "New Triggered Campaign-90daysinactive", "event_name": "90daysactive"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "STOP_EVENT_INACTIVE_180_DAYS":
                dataObj = 'window._peq.push(["add-to-trigger", { "campaign_name": "New Triggered Campaign-180daysinactive", "event_name": "180daysactive"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "STOP_EVENT_NOT_PURCHASED":
                dataObj = 'window._peq.push(["add-to-trigger", { "campaign_name": "New Triggered Campaign-notpurchased", "event_name": "purchased"}]);';
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            case "ORDER_CONFIRM_EVENT":
                dataObj = 'window._peq.push(["add-to-trigger",{'+
                    '"campaign_name": "New Triggered Campaign-orderconfirmation",'+
                    '"event_name": "orderplaced",'+
                    '"data": {'+
                        '"orderid":"'+data.orderNumber+'",'+
                        '"imageurl": "'+data.imageurl+'"'+
                    '}'+
                '}]);';                
                script.innerHTML = dataObj;
                document.body.appendChild(script);
                break;

            default :  
                break;
        }
    } catch (e) {
        console.log("issue while tracking push engage event "+ e);
    } 
}
module.exports = {
    pushEngageEvent: pushEngageEvent
};
