/**
 * Fires Comment Sold event based on the event name
 * @param {string} eventName - event name
 * @param {Object} data - event data in JSON
 */
function fireCSEvent(eventName, data) {
    try {
        if (resources.prefs.isCSTrackingEnabled == "true" && typeof(videeoCommand) != "undefined") {
            switch (eventName) {
                case "ADD_TO_CART":
                    var dataObj = {
                        product: {
                            sku: data.sku.toString(), //item SKU - more reliable way to match products then the ID (especially since we currently aren't asking for the ID)
                            price: parseFloat(data.price), //float price of a single unit of the variant
                            currency: data.currency, //we should default to USD but it would be good to have support for other currencies for the future
                            quantity: data.quantity, //number of the variant purchased
                        }
                    };   
                    videeoCommand("addToCart", dataObj); // eslint-disable-line no-undef
                    break;
                case "PURCHASE":
                    var orderObj = {};
                    var order = {};
                    order.orderId = data.orderNumber;
                    order.orderTotal = data.priceTotal ? parseFloat(data.priceTotal.replace("$", "")) : 0;
                    order.shipping = data.totals.totalShippingCost ? parseFloat(data.totals.totalShippingCost.replace("$", "")) : 0;
                    order.tax = data.totals.totalTax ? parseFloat(data.totals.totalTax.replace("$", "")) : 0;
                    order.discount = data.totals.totalDiscounts ? parseFloat(data.totals.totalDiscounts.replace("$", "")) : 0;
                    order.currency = "USD";
                    var productsArr = [];
                    for (var i=0; i < data.items.items.length; i++) {
                        var prod = data.items.items[i];
                        var product = {};
                        product.sku = prod.id;
                        product.price = parseFloat(prod.price.sales.value);
                        product.currency = "USD",
                        product.quantity = prod.quantity;
                        product.productURL = "https://shoplc.com/p/"+prod.id+".html",
                        productsArr.push(product);
                    }
                    orderObj.order = order;
                    orderObj.products = productsArr;
                    videeoCommand("checkout", orderObj); // eslint-disable-line no-undef
                    break;
                default :  
                    break;
            }

        }
    } catch (e) {
        console.log("issue while tracking commentsold event "+ e);
    } 
}

module.exports = {
    fireCSEvent: fireCSEvent
};
