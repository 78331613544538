module.exports = function () {
    $(document).on("click", "#sendComment", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var comment = $("#comments").val();
        var errorMessage = $(this).data("errorMessage");
        var successMessage = $(this).data("successMessage");
        var userSpecificMessage = $(this).data("userSpecificMessage");
        if (!comment) {
            alert(errorMessage);
        } else {
            $.ajax({
                url: url,
                method: "POST",
                data: {comment: comment},
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        $("#comments").val("");
                        alert(successMessage);
                    } else {
                        alert(userSpecificMessage);
                    }
                }
            });
        }
    });

    $(document).on("click", "#sendCommentNonLogin", function (e) {
        e.preventDefault();

        var comment = $("#comments").val();
        var mobile = $("#host-form-phone").val();
        var email = $("#host-form-email").val() != "" ? $("#host-form-email").val() : "";
        var emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        var phnPattern =  /[1-9]{1}[0-9]{9}/;
        var isValidate =$("#livetv-host-form")[0].checkValidity();

        if (comment != "") {
            $("#form-host-message-error").hide();
            isValidate = true;
        } else {
            $("#form-host-message-error").show();
            isValidate = false;

        }
        if (emailPattern.test(email)) { 
            $("#form-email-error").hide();
            $("#form-phone-error").hide();
            isValidate = true;
        } else if (phnPattern.test(mobile)) {
            $("#form-phone-error").hide();
            $("#form-email-error").hide();
            isValidate = true;
        } else {
            $("#form-phone-error").show();
            $("#form-email-error").show();
            isValidate = false;
        }

        if (mobile.length > 0 && mobile.length < 10) {
            $("#form-phone-error").show();
            isValidate = false;
        }
        if (!emailPattern.test(email) && email != "") {
            $("#form-email-error").show();
            isValidate = false;
        }


        if (comment != "" && (email != "" || mobile != "") && isValidate) {
            isValidate = true;
        } else {
            isValidate = false;
        }

        if (!isValidate) {
            return;
        }
        else
        {
            var url = $(this).attr("href");
            var candian = $(".canadaChecked")[1].checked ? $(".canadaChecked")[1].checked : $(".canadaChecked")[0].checked;
            var acceptTerms = $("#acceptTerms").prop("checked");
            var successMessage = $(this).data("successMessage");
            var userSpecificMessage = $(this).data("userSpecificMessage");
            if (!acceptTerms) {
                alert("Please Accept Terms & Condiitons");
            }
            else {
                $.ajax({
                    url: url,
                    method: "POST",
                    data: { comment: comment, mobile: mobile, email: email, candian: candian},
                    dataType: "json",
                    success: function (data) {
                        if (data.success) {
                            $("#comments").val("");
                            $("#host-form-phone").val("");
                            $("#host-form-email").val("");
                            $(".canadaChecked")[1].checked = false;
                            $(".canadaChecked")[0].checked = false;
                            $("#acceptTerms").prop("checked", false);
                            $(".invalid-feedback").hide();
                            alert(successMessage);
                        } else {
                            alert(userSpecificMessage);
                        }
                    }
                });
            }        
        }
    });
};
