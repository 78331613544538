/**
 * @description Return live tv preferences
 * @returns {Object}
 */
module.exports = (function () {
    var liveTvPreferences = {
        getLiveTvProductUrl: "",
        addToCartUrl: "",
        pubnubSubscribeKey: "",
        pubnubPublishKey: "",
        pubnubLiveTvAuctionChannel: "",
        technicalError: ""
    };

    if (!window || !window.auctions || !window.auctions.liveTvAuction) {
        return liveTvPreferences;
    }

    var windowAuctionsLiveTvPreferences = window.auctions.liveTvAuction;

    liveTvPreferences.technicalError = windowAuctionsLiveTvPreferences.technicalError;
    liveTvPreferences.noAuctionMessage = windowAuctionsLiveTvPreferences.noAuctionMessage;
    liveTvPreferences.addToCartUrl = windowAuctionsLiveTvPreferences.addToCartUrl;
    liveTvPreferences.getLiveTvProductUrl = windowAuctionsLiveTvPreferences.getLiveTvProductUrl;
    liveTvPreferences.pubnubSubscribeKey = windowAuctionsLiveTvPreferences.pubnubSubscribeKey;
    liveTvPreferences.pubnubPublishKey = windowAuctionsLiveTvPreferences.pubnubPublishKey;
    liveTvPreferences.pubnubLiveTvAuctionChannel = windowAuctionsLiveTvPreferences.pubnubLiveTvAuctionChannel;
    liveTvPreferences.logLiveTvProductPendingUrl = windowAuctionsLiveTvPreferences.logLiveTvProductPendingUrl || "";

    return liveTvPreferences;
})();
