var debounce = require("lodash/debounce");
var $navCarousel;
var $window = $(window);

function higlightNavItem(selectedItem, zoom) {
    $(".js-pdp-nav-carousel" + zoom).find(".nav-item").removeClass("highlighted");
    selectedItem.addClass("highlighted");
}

// Set configuration for navigation slider depending on width of the screen
function navCarouselConfigs(zoom = "") {
    var carouselMode = "horizontal";
    var minSlides = 4.5;
    var maxSlides = 4.5;
    var slideMargin = 0;
    var showControls = false;
    var touchEnabled = true;

    var isVerticalCarousel = (($window.width() > 769) && ($(".product-quickview").length < 1 || zoom != ""));
    if (isVerticalCarousel) {
        carouselMode = "vertical";
        minSlides = 7;
        maxSlides = 7;
        slideMargin = 8;
        showControls = true;
        touchEnabled = false;
    }
    if (zoom != "") {
        showControls = false;
    }

    var isAuctionPDPCarousel = ($(".pdp.auction-pdp").length > 0 && zoom === "");
    if (isAuctionPDPCarousel) {
        carouselMode = "horizontal";
        minSlides = 4;
        maxSlides = 4;
        slideMargin = 0;
        showControls = true;
        touchEnabled = true;
    }

    var isAuctionQVCarousel = ($(".product-quickview.auction-quickview").length > 0 && zoom === "");
    if (isAuctionQVCarousel) {
        carouselMode = "horizontal";
        minSlides = 3;
        maxSlides = 3;
        slideMargin = 0;
        showControls = true;
        touchEnabled = true;
    }
    var isLandingPDPCarousel = ($(".pdp-landingPage").length > 0 && zoom === "");
    if (isLandingPDPCarousel) {
        carouselMode = "horizontal";
        minSlides = 4;
        maxSlides = 4;
        slideMargin = 0;
        showControls = true;
        touchEnabled = true;
    }

    return {
        wrapperClass: "bx-wrapper " + carouselMode + "-mode",
        mode: carouselMode,
        slideWidth: "100%",
        minSlides: minSlides,
        maxSlides: maxSlides,
        controls: showControls,
        hideControlOnEnd: true,
        infiniteLoop: false,
        touchEnabled: touchEnabled,
        slideMargin: slideMargin,
        moveSlides: 1,
        nextText: "",
        prevText: "",
        pager: false
    };
}

// This function can be called either to initialize the navigation slider for the first time or to reload the slider when its size changes.
function configureNavCarousel(initNewCarousel, zoom) {
    var config = navCarouselConfigs(zoom);

    if ($navCarousel && $navCarousel.reloadSlider && !initNewCarousel && $(".product-quickview").length < 1) {
        // If the slider has already been initialized, reload it.
        $navCarousel.reloadSlider(config);
    } else {
        // Otherwise, initialize the slider.
        $navCarousel = $(".js-pdp-nav-carousel" + zoom).bxSlider(config);
    }
}

function testImagesCarousel() {
    
    function testImage(URL, position) {
        var tester = new Image();
        tester.onerror = imageNotFound;
        tester.src = URL;
        tester.setAttribute("data-position", position);
    }

    function imageNotFound(event) {
        var element = event.path[0];
        var elementCollection = document.querySelectorAll("[data-position='" + element.dataset.position + "']");
        var defaultImg = $(".pdp-main-carousel").attr("data-img-default");
        var htmlString = "<div class=\"\" id=\"staticImagePDP\" tabindex=\"-1\" role=\"dialog\">" +
        "<img class=\"img-fluid js-zoom-img lazyloaded w-100 d-block\" src=" + defaultImg +"> </img>" +
        "<div>";

        elementCollection = Array.from(elementCollection);
        elementCollection.map((elem) => { // NOSONAR
            $(elem).closest(".owl-item").empty().append(htmlString);
        });

        // rebuild the carousel after we delete the problem image
        //$(".js-pdp-main-carousel").trigger("remove.owl.carousel", [element.dataset.position]).trigger("refresh.owl.carousel");
        
        // load static image in case all images broken
        //if (!$(".js-pdp-main-carousel .owl-item").hasClass("active")) {
        //    $(".js-pdp-main-carousel .owl-stage").empty().append(htmlString);
        //}
    }

    var imageNew = $(".quickview-images .owl-item img.img-fluid");
    imageNew.each((index, elem) => {
        testImage(elem.src, index);
    });

}

module.exports = {
    // Initialize main carousel with product images
    initMainCarousel: function (zoom = "") {
        testImagesCarousel();
        var $mainCarousel = $(".js-pdp-main-carousel" + zoom);

        $mainCarousel.on("changed.owl.carousel", function (e) {
            var $selectedItem = $(".js-pdp-nav-carousel" + zoom).find(".nav-item[id=\"slide-" + e.item.index + "\"]");

            if ($selectedItem.length > 0) {
                higlightNavItem($selectedItem, zoom);
            }
        });
        var nav = true;
        if (zoom != "")
            nav = false;
        if ($mainCarousel.length > 0) {
            $mainCarousel.owlCarousel({
                nav: nav,
                items: 1,
                navText: "",
                dots: false,
                loop: false,
                URLhashListener: false,
                navContainerClass: "owl-nav owl-nav--secondary"
            });
        }
    },
    //Initialize navigation carousel with images
    initNavCarousel: function (initNewCarousel, zoom = "") {
        var newCarousel = initNewCarousel ? initNewCarousel : false;

        configureNavCarousel(newCarousel, zoom);

        $(document).on("click touchstart mouseover", ".js-pdp-nav-carousel" + zoom + " .nav-item", function () {
            var $this = $(this);

            $this.closest(".js-pdp-nav-carousel" + zoom).find(".nav-item").removeClass("highlighted");
            $this.addClass("highlighted");

            var index = $this.attr("data-index");
            $(".js-pdp-main-carousel" + zoom).trigger("to.owl.carousel", [index, 1]);
        });
        
        $(document).on("click touchstart mouseover", ".js-pdp-nav-carousel" + zoom + " .live-tv-btn", function () {
            var $this = $(this);

            $this.closest(".js-pdp-nav-carousel" + zoom).find(".nav-item").removeClass("highlighted");
            $this.addClass("highlighted");

            var index = 0;
            $(".js-pdp-main-carousel" + zoom).trigger("to.owl.carousel", [index, 1]);
        });
        
        $window.on("resize", debounce(function () {
            configureNavCarousel(newCarousel, zoom);
        }, 300));
    }
};
