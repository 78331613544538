module.exports = {
    handlePostCartAdd: function (response) {
        if (response.error) {
            var quickViewModal = $("#quickViewModal");

            if (quickViewModal.length > 0 && quickViewModal.hasClass("show")) {
                quickViewModal.modal("hide");
            }

            if (response.noStock) {
                $("[data-max-quantity-text]").html(response.message);
            }

            $("#maximum-quantity-modal").modal("show");
        } else {
            $(".minicart").trigger("count:update", response);
            if ($(".add-to-cart-messages").length === 0) {
                $("body").append(
                    "<div class=\"add-to-cart-messages\"></div>"
                );
            }

            $(".add-to-cart-messages").append(
                "<div class=\"alert alert-success add-to-basket-alert text-center\" role=\"alert\">" +
                response.message +
                "</div>"
            );

            setTimeout(function () {
                $(".add-to-basket-alert").remove();
            }, 5000);
        }
    },
};
