/* global DY */
var CryptoJS = require("crypto-js");

/**

 * Fires data layer event based on the event name
 * @param {string} eventName - event name
 * @param {Object} data - datalayer JSON
 */
function fireDYEvent(eventName, data) {
    try {
        switch (eventName) {
            case "HOME":
                break;
            case "NEW_REGISTRATION":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name: "Signup",
                        properties: {
                            dyType: "signup-v1",
                            hashedEmail:data.hashedemail
                        }
                    });
                }
                break;
            case "LOGIN":
                if (DY.API != undefined && data.customerNo != "" && data.customerNo != null) {
                    DY.API("event", {
                        name: "Login",
                        properties: {
                            dyType: "login-v1",
                            hashedEmail:data.hashedemail,
                            cuid: data.customerNo
                        }
                    });
                }
                break;
            case "SYNC_CART":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name: "Sync cart",
                        properties: {
                            dyType: "sync-cart-v1",
                            currency: "USD",
                            cart: data.syncCart
                        }
                    });
                }
                break;
            case "CATEGORY":
                break;
            case "CART":
                break;
            case "LIVETVPDP":
                break;
            case "ADD_TO_CART":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name: "Add to Cart",
                        properties: {
                            dyType: "add-to-cart-v1",
                            value: parseFloat(data.price)*data.quantity,
                            currency: "USD",
                            productId: data.pid,
                            quantity: data.quantity
                        }
                    });
                }
                break;
            case "ADD_TO_WISHLIST":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name: "Add to Wishlist",
                        properties: {
                            dyType: "add-to-wishlist-v1",
                            productId: data.pid
                        }
                    });
                }
                break;
            case "REMOVE_FROM_CART":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name: "Remove from Cart",
                        properties: {
                            dyType: "remove-from-cart-v1",
                            value: data.price*data.quantity,
                            currency: "USD",
                            productId: data.pid,
                            quantity: data.quantity
                        }
                    });
                }
                break;
            case "PURCHASE":
                if (DY.API != undefined && data) {
                    var cart = [];
                    for (var i=0; i < data.items.items.length; i++) {
                        var prod = data.items.items[i];
                        var item = {};
                        item.productId = prod.id;
                        item.quantity = prod.quantity;
                        item.itemPrice = prod.price.sales.value*prod.quantity;
                        cart.push(item);
                    }
                    DY.API("event", {
                        name: "Purchase", // Human-readable name, not used to identify an event type
                        properties: {
                            uniqueTransactionId: data.orderNumber, // Will remove redundant events. Must be a string. Max 64 characters.
                            dyType: "purchase-v1", // Identifies this event as a purchase
                            value: data.fullPayableAmount, // Total cart value in actual payment currency, as float (dollars dot cents)
                            currency: "USD", // Optional non-default currency used
                            cart: cart
                        }
                    });
                }
                break;
            case "SPA_QUICK_VIEW":
                if (DY.API != undefined) {
                    DY.API("spa", {
                        context: {
                            type: "PRODUCT",
                            data: [data.pid]
                        },
                        countAsPageview: false
                    });
                }
                break;
            case "NEWSLETTER_SUBSCRIPTION":
                if (DY.API != undefined) {
                    DY.API("event", {
                        name:"Newsletter Subscription",
                        properties:{
                            dyType:"newsletter-subscription-v1",
                            hashedEmail: CryptoJS.SHA256(data.email.toLowerCase()).toString(CryptoJS.enc.Hex)
                        }
                    });
                }
                break;
            case "SPA_LIVE_TV":
                if (DY.API != undefined) {
                    DY.API("spa", {
                        context: {
                            type: "OTHER"
                        },
                        countAsPageview: false
                    });
                }
                break;
            case "SPA_CART":
            case "SPA_MINI_CART":
                if (DY.API != undefined) {
                    var productIds = [];
                    data.forEach(element => productIds.push(element.id));
                    DY.API("spa", {
                        context: {
                            type: "CART",
                            data: productIds
                        },
                        countAsPageview: false
                    });
                }
                break;
            case "SPA_PLP":
                if (DY.API != undefined) {
                    var cgid = "";
                    if ($(".page").data("querystring") && $(".page").data("querystring").indexOf("cgid") > -1) {
                        $(".page").data("querystring").split("&").forEach(element => {
                            if (element.indexOf("cgid") > -1) {
                                cgid = element.split("=")[1];
                            }
                        });

                        if (cgid) {
                            DY.API("spa", {
                                context: {
                                    type: "CATEGORY",
                                    data:[cgid]
                                },
                                countAsPageview: false
                            });
                        }
                    }
                }
                break;
        }
    } catch (e) {
        return;
    }
}

module.exports = {
    fireDYEvent: fireDYEvent
};
