"use strict";
function logGAEvent(orderNo, message) {
    try {
        var url = $(document).find("#gaLogs").attr('href');
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: {orderId: orderNo,
                msg: message},
            error: function () {
                console.error("issue while tracking data in GA for purchase event");
            }
        });
    } catch(e) {
        console.error("error while logging request for GA {0} "+e);
    }
}

module.exports = {
    logGAEvent: logGAEvent
};