/**
 * checks every second auction timer and calls
 * updateTimerValue function to update remaining time
 */
function updateAuctionTimer() {
    var count = 1;
    $.each($(".auctionTimer"), function () {
        if (!$(this).data("isExpired")) {
            updateTimerValue($(this), count);
            count++;
        }
    });
}

/**
 * updates timer remaining value
 * @param {jquery element} $element timer span element
 * @param {number} count loop count index
 */
function updateTimerValue($element, count) {
    var expirationDate = Date.parse($element.data("expirationDate"));
    //added return so that we don't break all script in case no expiration date provided
    if (!expirationDate) {
        $element.data("isExpired", true);
        $("body").trigger("auction:expired", $element);
        return;
    }

    $.ajax({
        url: $(".timer-script").data("server-time-url"),
        type: "get",
        success: function (response) {
            $(".ra-server-time").val($(response).val());
            $(".ra-pdp-server-time").val($(response).val());

            var now = parseInt($(".ra-server-time").val(), 10) || parseInt($(".ra-pdp-server-time").val(), 10);
            var remainingTime = expirationDate - now;
            var isExpired = isNaN(remainingTime) || remainingTime <= 0;
            $element.data("isExpired", isExpired);
            if (isExpired) {
                $("body").trigger("auction:expired", $element);
                if ($element.hasClass("watchlist-timer")) {
                    $element.attr("data-is-expired", $element.data("isExpired"));
                    $element.closest(".bid-table-item").hide();
                }
                return;
            }
            var days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            var hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

            var secondsLeft = parseInt(seconds, 10) + (parseInt(minutes, 10) * 60) + (parseInt(hours, 10) * 60 * 60) + (parseInt(days, 10) * 24 * 60 * 60);

            var stringDate = "<div class='simple-timer timer-quick-view timer-quick-view-" + count + "' data-seconds-left=" + secondsLeft + "></div>";

            $element.empty().append(stringDate);

            var classNames = {
                days:"quick-view-days",
                hours:"quick-view-hours",
                minutes:"quick-view-minutes",
                seconds:"quick-view-seconds",
                clearDiv:"quick-view-clearDiv",
                timeout:"quick-view-timeout"
            };
            if ($(".bid-watchlist-table").length > 0) {
                classNames = {
                    days:"watch-list-days",
                    hours:"watch-list-hours",
                    minutes:"watch-list-minutes",
                    seconds:"watch-list-seconds",
                    clearDiv:"watch-list-clearDiv",
                    timeout:"watch-list-timeout"
                };
            } else if ($(".bid-history-table").length > 0) {
                classNames = {
                    days:"bid-history-days",
                    hours:"bid-history-hours",
                    minutes:"bid-history-minutes",
                    seconds:"bid-history-seconds",
                    clearDiv:"bid-history-clearDiv",
                    timeout:"bid-history-timeout"
                };
            } else if ($(".auction-cart").length > 0) {
                classNames = {
                    days:"auction-cart-days",
                    hours:"auction-cart-hours",
                    minutes:"auction-cart-minutes",
                    seconds:"auction-cart-seconds",
                    clearDiv:"auction-cart-clearDiv",
                    timeout:"auction-cart-timeout"
                };
            } else if ($("#checkout-main").length > 0) {
                classNames = {
                    days:"auction-checkout-days",
                    hours:"auction-checkout-hours",
                    minutes:"auction-checkout-minutes",
                    seconds:"auction-checkout-seconds",
                    clearDiv:"auction-checkout-clearDiv",
                    timeout:"auction-checkout-timeout"
                };
            }

            $(".timer-quick-view-" + count).startTimer({
                classNames: classNames,
                onComplete: function (element) {
                    $("body").trigger("auction:expired", element);
                    var $bidtableitem = element.closest(".bid-table-item");
                    if (element.parents(".item-timer").find(".watchlist-timer").length > 0) {
                        $(element).parents(".bid-table-item").find(".item-bid-container").spinner().start();
                        element.find(".watch-list-seconds").html("<b>00</b>s");
                        element.parents(".item-timer").find(".watchlist-timer").attr("data-is-expired", true);
                        //element.closest(".bid-table-item").hide();
                        $bidtableitem.find(".max-bid-button").attr("disabled", true);
                        $bidtableitem.find(".bid-now-button").attr("disabled", true);
                    }
                    if (element.parents(".item-timer").find(".timer-quick-view").length > 0) {
                        $(element).parents(".main-info-container-left").find(".bid-buttons-wrap").spinner().start();
                    }
                }
            });
        }
    });
}

function promotionShippingTimer() {
    setInterval(() => {
        var getEndDate = $(".auction-promotion-shipping").attr("date-promotion-end");

        var ts1 = new Date().getTime();
        var ts2 = new Date(getEndDate).getTime();

        var d, h, m, s;

        var ms = ts2 - ts1;

        s = Math.floor(ms / 1000);
        m = Math.floor(s / 60);
        s = s % 60;
        h = Math.floor(m / 60);
        m = m % 60;
        d = Math.floor(h / 24);
        h = h % 24;

        if (d < 10) {
            d = "0" + d;
        }
        if (h < 10) {
            h = "0" + h;
        }
        if (m < 10) {
            m = "0" + m;
        }
        if (s < 10) {
            s = "0" + s;
        }
        var promotionTimerUpdated = d + " : " + h + " : " + m + " : " + s;
        $(".auction-countdown").html(promotionTimerUpdated);
    }, 1000);
}
promotionShippingTimer();

module.exports.updateAuctionTimer = updateAuctionTimer;
