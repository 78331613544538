var modalHelper = require("./modalHelper");

module.exports = {
    generateErrorModal: function (responseObject) {
        var generatedModal = null;
        switch (responseObject.errorCode) {
            case 1:
                generatedModal = modalHelper.generateMaxItemCartModal(responseObject.errorMessage, responseObject.redirectUrl);
                break;
            default: break;
        }
        return generatedModal;
    }
};
