function zoomRefresh() {
    $(".zoom-modal-nav").find(".nav-item").removeClass("active");
    $(".zoom-modal-img").find(".js-zoom-container").hide();
}

module.exports = {
    initZoom: function (zoom = "") {
        for (let img of document.querySelectorAll('.pdp-main-carousel .js-zoom-img')) {
            if (img.classList.contains('d-none')) {
                img.classList.remove('d-none');
                img.classList.add('d-block');
            }
        }

        var $zoomContainer = $(".js-pdp-main-carousel" + zoom).find(".js-zoom-container");

        if ($(window).width() < 769) {
            $zoomContainer = $(".zoom-modal-img").find(".js-zoom-container");
        }

        $(document).ready(function () {
            if ($zoomContainer.length > 0) {
                $zoomContainer.each(function () {
                    var $this = $(this);
                    var $img = $this.find(".js-zoom-img");

                    if ($img.length > 0) {
                        $this.zoom({
                            url: $img.data("zoom-src") ? $img.data("zoom-src") : $img.attr("src")
                        });
                    }
                });
            }
        });
    },
    updateZoomImg: function () {
        $(document).on("click", ".zoom-modal-nav .nav-item", function (e) {
            e.preventDefault();

            var $this = $(this);
            var activeSlide = $(this).attr("href").substring(1);
            var $activeModalImage = $(".zoom-modal-img").find(".js-zoom-container[data-hash=\"" + activeSlide + "\"]");

            zoomRefresh();
            $this.addClass("active");
            $activeModalImage.show();
        });
    },
    showZoomModal: function () {
        $("body").on("show.bs.modal", "#zoomModal", function () {
            var $activeZoomNav = $(".zoom-modal-nav").find(".nav-item").first();
            var $activeModalImage = $(".zoom-modal-img").find(".js-zoom-container").first();

            zoomRefresh();

            if ($activeZoomNav.length > 0) {
                $activeZoomNav.addClass("active");
            }

            if ($activeModalImage.length > 0) {
                $activeModalImage.show();
            }
        });
    }
};
