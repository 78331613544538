

/**
 * Return tooltip value for which i icon is required.
 * @param {Object} keys - product specification tool tip keys
 * @param {string} tooltipValue - current tooltip value
 * @returns {string} tooltip value if found
 */
function getTooltipKeyData(keys, tooltipValue) {
    var value = "";
    keys.forEach(function (key) {
        if (key === tooltipValue) {
            value = key;
        }
    });
    return value;
}

/**
 * Append i icon dynamically for the product enrichment.
 */
function appendInfoIcon() {
    var $toolTipKeys = $(".productSpecificationTooltipKeys");
    if ($toolTipKeys.length > 0) {
        var keys = $toolTipKeys.data("specification-tooltip-keys").split("|");

        $(".specifications table tbody tr").each(function () {
            var tableLeftData = $(this).children("td").eq(0).text().trim();
            var tableRightData = $(this).children("td").eq(1).text().trim();
            var tooltipKey = "";
            if (tableLeftData === getTooltipKeyData(keys, tableLeftData)) {
                tooltipKey = tableLeftData;
            }
            if (tableRightData === getTooltipKeyData(keys, tableRightData)) {
                tooltipKey = tableRightData;
            }

            if (tooltipKey && tooltipKey !== "" && $(this).children("td").eq(1).find("a.icon-display").length <= 0) {
                $(this).children("td").eq(1).find("span:last").append("<a class='icon-display' data-icon='" + tooltipKey + "'></a>");
            }
        });
    }
}

module.exports = {
    getProductSpecificationToolTipData: function () {
        var specificationArray = [];
        $(".specifications table tbody tr").each(function () {
            specificationArray.push($(this).children("td").eq(0).text().trim());
            specificationArray.push($(this).children("td").eq(1).text().trim());
        });

        var url = $(".product-specification-attributes").data("url");

        if (specificationArray.length > 0) {
            $(".specifications").spinner().start();
            $.ajax({
                url: url + "?specification=" + encodeURIComponent(specificationArray),
                method: "GET",
                dataType: "html",
                success: function success(response) {
                    $("#productSpecificationsTooltips").html($(response).html());
                    appendInfoIcon();
                    $.spinner().stop();
                },
                error: function error() {
                    $.spinner().stop();
                }
            });
        }
    }
};
